import React,{Component} from 'react'
import Snap from 'snapsvg-cjs'



class IconHouseAnimated extends Component{
componentDidMount =() => {
   const cerchio = Snap("#cerchio")
   const cc = Snap("#cc")
   const logo2 = Snap("#logo2")
   const ki = Snap("#ki")

   
   logo2.attr({opacity: 0})
   cerchio.animate({ strokeDashoffset: "-320", stroke:'yellow'}, 2000, window.mina.linear, function(){
      logo2.animate({ opacity: '.9'}, 800, window.mina.linear)
      cc.animate({fill:'#fff124', opacity: '.9'}, 800, window.mina.linear, function(){
         cc.animate({transform: 't-100,0'},400, window.mina.linear)
         logo2.animate({transform: 't-100,0'},800, window.mina.linear, function(){
            ki.animate({ 'opacity': ki.attr('data-o')},1000, window.mina.linear)
         })
      })
   })
  

   
}
render(){ 
    return(
      <svg x="0px" y="0px" width="300" height="200" viewBox="0 0 300 200">
        <linearGradient 
                    x1="0%" y1="98%" x2="100%" y2="50%"
                    id="shadow">
                    <stop offset="34%" stopColor="#e7af02" stopOpacity=".8"></stop>
                    <stop offset="43%" stopColor="#f4c900"></stop>
                     <stop offset="100%" stopColor="#f4c900"></stop> 
       </linearGradient>
      <g>   
      
      <path 
      id="cerchio"
      strokeWidth="10" stroke="white" fill="none" 
      strokeMiterlimit="1" 
      strokeLinecap="round" strokeLinejoin="round" strokeDasharray="20 320"
      d="m 10 200 a 1 1 0 0 1 60 0 a 1 1 0 0 1 50 0 a 1 1 0 0 1 30 0 v-80" />
      </g>  
      <g>
       <g >
       <text id="ki" x="135" y="95" fontSize="24" fontWeight="bold" fill="white" opacity="0" data-o=".9" >KILOWATT</text>   
      </g> 
      </g>
      <svg viewBox="0 0 410 497" x="20" y="55">
      <path 
      id="cc"
      fill="#fff124" opacity="0"
      d="M 0,65 105,204 211,202 58,0 Z"
>     </path> 
      <path
      id="logo2"
      fill="url(#shadow)"
      d="M 58.442977,1.1427991 109.04056,67.90844 169.34044,1.1427991 Z"
      />
      </svg>
      </svg>
    )
}
}
export default IconHouseAnimated  
