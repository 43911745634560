import React, {Component} from 'react'
import './raiting.css'
import Snap from 'snapsvg-cjs'

class Raiting extends Component {
    constructor(props){
        super(props)
        this.pressPiuBolletta = this.pressPiuBolletta.bind(this)
        this.pressMenusBolletta = this.pressMenusBolletta.bind(this)
        this.pressReleaseBollettaPlus = this.pressReleaseBollettaPlus.bind(this)
        this.pressReleaseBollettaMenus = this.pressReleaseBollettaMenus.bind(this)  
        this.statoComponentRaiting = this.props.click.bind(this)
        this.rateComporation = this.props.rates.bind(this)
            this.state = {
                bolletta: this.props.bolletta,
                clientBolletta: this.props.bolletta,
                divided: this.props.bolletta / 100,
                pressCounter: 0,
                percentuale: 0
            
        }
    }
  
    componentDidMount = () => {
        const s = Snap("#textBox")
        const reactions = Snap("#reactions")
        const text = ['Ottimo...!','e la tua Bolletta di quanto è ?']
        const t = s.text(130,20,text[0])
        t.attr({ fontSize:'16'})
        reactions.text(164,40,'aggiungi l\'importo')
        reactions.text(162,49,'premendo i pulsanti')
        reactions.attr({ fontSize:'10', fill:'gray',opacity:'0'})
        
        for(let index = 0;index < text.length;index++){
            setTimeout(function(){
               Snap.animate(0,1, function(value){
               t.attr({text: text[index],opacity: value, x:"50", fontSize: '16'})
               },1000, window.mina.bounce )
               reactions.animate({opacity:'1'}, 300, window.mina.linear)
            },2000, )
            }

    }

    pressPiuBolletta = () => {
    const plusBolletta = Snap("#bolletta-plus")
    this.statoComponentRaiting(false)  
    plusBolletta.animate({ transform: 's1.2'}, 400, window.mina.bounce)
    this.timerPlusClient = setInterval(()=> {
    this.setState({  pressCounter: this.state.pressCounter + 0.01, clientBolletta: this.state.clientBolletta + 0.01, percentuale: this.state.pressCounter / this.state.divided })
    }, 10)
   
    }
    pressReleaseBollettaPlus = () => {
    const plusBolletta = Snap("#bolletta-plus")
    this.statoComponentRaiting(true)   
    this.rateComporation(this.state) 
    plusBolletta.animate({ transform: 's1'}, 400, window.mina.bounce)
    clearInterval(this.timerPlusClient)
    }
    pressMenusBolletta = () => {
    const menusBolletta = Snap("#bolletta-menus")
    this.statoComponentRaiting(false)  
    menusBolletta.animate({ transform: 's1.2'}, 400, window.mina.bounce)
    this.timerMenusClient = setInterval(()=> {
    this.setState({  pressCounter: this.state.pressCounter - 0.01, clientBolletta: this.state.clientBolletta - 0.01, percentuale: this.state.pressCounter / this.state.init})
    }, 10)
    }
    pressReleaseBollettaMenus = () => {
    const menusBolletta = Snap("#bolletta-menus")
    this.statoComponentRaiting(true)  
    this.rateComporation(this.state) 
    menusBolletta.animate({ transform: 's1'}, 400, window.mina.bounce)
    clearInterval(this.timerMenusClient)
    }
    
   
    render(){
    const percento = this.state.pressCounter / this.state.divided
    const isDesktop = /Windows|Macintosh|X11|Ubuntu|Linux/i.test(navigator.userAgent)
    const isMobile = /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent)
        return( 
             <div className="raiting-vote">
                <svg id="raiting" width="300" height="130" viewBox="0 0 300 130">
                <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#f3ed43ff" stopOpacity=".9" />        
                <stop offset="100%" stopColor="#f39f43ff" stopOpacity="1" />
                </linearGradient> 
                <linearGradient id="grad2" x1="50%" y1="0%" x2="50%" y2="100%">
                <stop offset="3%" stopColor="#7AA1F5" stopOpacity="1" />   
                <stop offset="50%" stopColor="#7AA1F5" stopOpacity="1" />           
                <stop offset="100%" stopColor="#5F7BF5" stopOpacity="1" />
                </linearGradient>
                <linearGradient id="grad3" x1="0%" y1="0%" x2="0%" y2="70%">
                <stop offset="0%" stopColor="#ff6100ff" stopOpacity=".9" />        
                <stop offset="100%" stopColor="#F52F2F" stopOpacity="1" />
                </linearGradient>
                <g id="textBox"></g>
                <g id="reactions"></g>
                
                <g id="bolletta">
                <rect id="box-base-2" stroke="gray" x="45" y="40" width="70" height="80" fill="white"/>
                <rect id="box-base-1" stroke="#cacaca" 
                strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" x="40" y="46" width="70" height="80" fill="white" ></rect>
                    <line x1="55" x2="90" y1="90" y2="90" stroke="#cacaca" strokeWidth="6" strokeLinejoin="round" strokeLinecap="round" />
                    <line x1="55" x2="80" y1="100" y2="100" stroke="#cacaca" strokeWidth="6" strokeLinejoin="round" strokeLinecap="round" />
                    <line x1="55" x2="90" y1="110" y2="110" stroke="#cacaca" strokeWidth="6" strokeLinejoin="round" strokeLinecap="round"  />
                    <text x="60" y="75" fontWeight="bold" fontSize="14" fill="#505050">{this.state.clientBolletta.toFixed(2)}</text>
                </g>
                <g id="button">
                {
                    isMobile &&
                    <g id="bolletta-menus"
                    onTouchStart={this.pressMenusBolletta}
                    onTouchEnd={this.pressReleaseBollettaMenus}
                    >
                    <circle cx="170" cy="85" r="25" fill="white" stroke="url(#grad1)" strokeWidth="4"/>
                    <circle cx="170" cy="85" r="20"  fill="url(#grad1)" ></circle>
                    <path d="m 160 79 l 5 3 l -5 3 v -1 l 4 -2 l -4 -2 v -1 z  " strokeWidth="2" stroke="#4d4d4dff"/>
                    <path d="m 180 79 l -5 3 l 5 3 v -1 l-4 -2 l 4 -2 v -1 z   " strokeWidth="2" stroke="#4d4d4dff" />
                    <path d="m 160 91 a 1 1 0 0 0 20 0z" fill="#4d4d4dff"/>
                    <text x="145" y="123" fontSize="12" fill="gray">diminuire</text>
                    </g>
                }
                { 
                    isDesktop &&
                    <g id="bolletta-menus"
                    onMouseEnter={this.pressMenusBolletta}
                    onMouseOut={this.pressReleaseBollettaMenus}
                    >
                    <circle cx="170" cy="85" r="25" fill="white" stroke="url(#grad1)" strokeWidth="4"/>
                    <circle cx="170" cy="85" r="20"  fill="url(#grad1)" ></circle>
                    <path d="m 160 79 l 5 3 l -5 3 v -1 l 4 -2 l -4 -2 v -1 z  " strokeWidth="2" stroke="#4d4d4dff"/>
                    <path d="m 180 79 l -5 3 l 5 3 v -1 l-4 -2 l 4 -2 v -1 z   " strokeWidth="2" stroke="#4d4d4dff" />
                    <path d="m 160 91 a 1 1 0 0 0 20 0z" fill="#4d4d4dff"/>
                    <text x="145" y="123" fontSize="12" fill="gray">diminuire</text>
                    </g>
                }
                {
                    isMobile &&
                    <g id="bolletta-plus"
                    onTouchStart={this.pressPiuBolletta}
                    onTouchEnd={this.pressReleaseBollettaPlus}
                    >
                    <circle cx="240" cy="85" r="25" fill="white" stroke="url(#grad2)" strokeWidth="4"/>
                    <circle id="cerchio-plus-int" cx="240" cy="85" r="20" fill="url(#grad2)"/>
                    <path d="m234 82 c 0 0 4 3 -8 1" strokeWidth="2" fill="none" stroke="#4d4d4dff"/>
                    <path d="m244 82 c 0 0 -4 3 8 1" strokeWidth="2" fill="none" stroke="#4d4d4dff"/>
                    <path d="m233 94 c 0 0 6.5 -3 13 0 " strokeWidth="3" fill="none" stroke="#4d4d4dff" strokeLinecap="round" strokeLinejoin="round" />
                    <text x="215" y="123" fontSize="12" fill="gray">aumentare</text>
                    </g>
                }
                {
                    isDesktop &&
                    <g id="bolletta-plus"
                    onMouseEnter={this.pressPiuBolletta}
                    onMouseOut={this.pressReleaseBollettaPlus}
                    >
                    <circle cx="240" cy="85" r="25" fill="white" stroke="url(#grad2)" strokeWidth="4"/>
                    <circle id="cerchio-plus-int" cx="240" cy="85" r="20" fill="url(#grad2)"/>
                    <path d="m234 82 c 0 0 4 3 -8 1" strokeWidth="2" fill="none" stroke="#4d4d4dff"/>
                    <path d="m244 82 c 0 0 -4 3 8 1" strokeWidth="2" fill="none" stroke="#4d4d4dff"/>
                    <path d="m233 94 c 0 0 6.5 -3 13 0 " strokeWidth="3" fill="none" stroke="#4d4d4dff" strokeLinecap="round" strokeLinejoin="round" />
                    <text x="215" y="123" fontSize="12" fill="gray">aumentare</text>
                    </g>
                }
                </g> 
                <g id="percento">
                <circle id="cipe" cx="35" cy="50" r={percento > 1 ? '20' : '0'} fill="#ffd700"/>
                <text id="text-cipe" x="22" y="53" fontSize={percento > 10 ? "10" : "12"} fontWeight="bold" opacity={percento > 1 ? '1': '0'} fill="gray" >{percento !== 0 ? '+ '+Math.floor(percento)+'%': ''}</text>    
                </g>  
                </svg>
         
         </div>    
        )
    }
}
export default Raiting
