import React from 'react'
import './icons.css'
const IconNotification = ({a, color}) => {
let fill = color ? '#fff124' : 'transparent'
return(
<svg 
className="Icon Notification"
viewBox="0 0 24 24">
<path 
stroke="#71706e"
fill="white"
d="M12 23c1.1 0 1.99-.89 1.99-1.99h-3.98c0 1.1.89 1.99 1.99 1.99z
m7-6v-6c0-3.35-2.36-6.15-5.5-6.83V3c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v1.17C7.36 4.85 5 7.65 5 11v6l-2 2v1h18v-1l-2-2z"/>
<path
fill={fill}
d="m 17 2 a 1 1 0 0 0 0 12 a -1 -1 0 0 0 0 -12 z">
</path>
{
    color &&  
    <text x="15" y="10.5" fontSize="8px" fill="#71706e">{a > 0 ? a : ''}</text>
}
</svg>
)

}

export default IconNotification