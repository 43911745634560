import React, { Component} from 'react'
import {FacebookShareButton , FacebookIcon,LinkedinShareButton,LinkedinIcon,WhatsappShareButton,WhatsappIcon,} from 'react-share'
import './fb.css'
import Back from './Back';
import Logo from './Logo';
//import SendMail from './SendMail';
class ButtonLike extends Component {
constructor(props){
  super(props)
  this.state = {}
}
backShow =(a)=>{
  if(a){
    window.location.reload()
  }
  
}
  render() {
    const url = "https://kilowatt-app.web.app"
    return (
      <div>
        <header className="App-header">
        <span className="header-title">
        kilowatt
        </span>
        </header>
    
      <div className="div-center-column-ines">
          <div className="div-ines"> 
          <Logo />
          </div>

          <div className="follow">
              <h1 className="h1_absolute">Condividi con i tuoi amici</h1> 
              <FacebookShareButton url={url} quote="Kilowatt" children="span">
              <FacebookIcon size={40} round />  
              </FacebookShareButton>
              <LinkedinShareButton
            url={url}
            windowWidth={750}
            windowHeight={600}
            className="Demo__some-network__share-button">
            <LinkedinIcon
              size={40}
              round />
          </LinkedinShareButton>
          <WhatsappShareButton url={url}>
            <WhatsappIcon size={40} round/>
          </WhatsappShareButton>
              </div>
          

          <div className="div-center-row retor">
          <Back click={this.backShow}/>
          </div>
          
          <div className="div-powered">
            <h3>from</h3>
            <span>Yumber Ramirez</span>
          </div>
       </div>
      </div>
     )
  }
}
export default ButtonLike

