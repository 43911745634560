import React,{Component} from 'react'
import './privacy.css'
import { IconCloseRound } from '../UI/Icons/IconClose';



class PrivacyCookie extends Component{

render(){
    const { isclose } = this.props 
    return(
     
  <div className="div-absolute-100">
    <header className="App-header">
        <span className="header-image">
        </span>
        <span className="header-title">Privacy 
          </span>
          <span className="header-caption" >
            <span onClick={isclose.bind(this, false)}>
            <IconCloseRound />
            </span>
          </span>
    </header>
    <div className="div-center-column">
            <div className="privacy-container">
            <div className="privacy-content"> 
            <div className="privacy-title">
            <span className="escudo"> 
            <svg width="28" height="28" viewBox="0 0 24 24">
              <path 
              stroke="#71706e"
              fill="white"
              d="M 12 1 L 3 5 v 6 c 0 5.55 3.84 10.74 9 12 c 5.16 -1.26 9 -6.45 9 -12 V 5 l -9 -4 Z" />
            </svg>
            </span>  
            <span>
              Privacy e Cookie del sito  KILOWATT
            </span>
            </div> 
            <p>
            L'obiettivo dell'informativa privacy è di fornire la massima trasparenza relativamente
            alle informazioni che il sito raccoglie e come le usa. In ottemperanza degli obblighi 
            derivanti dalla normativa nazionale (D. Lgs 30 giugno 2003 n. 196, Codice in materia di
            protezione dei dati personali, e successive modifiche) e europea (Regolamento europeo 
            per la protezione dei dati personali n. 679/2016, GDPR), il presente sito rispetta e 
            tutela la riservatezza dei visitatori e degli utenti, ponendo in essere ogni sforzo 
            possibile e proporzionato per non ledere i diritti degli utenti. 
            Siamo consapevoli di quanto sia importante la privacy per gli utenti e ci assicuriamo 
            di essere chiari riguardo ala modalità di raccolta, trattamento, divulgazione, 
            trasferimento e archiviazione dei dati personali. 
            </p>
            <ul>
            <li>- Informazioni fornite direttamente dall’utente al nostro sito </li>
            <li>- Dati raccolti dall'applicazione sull’utilizzo che l’utente fa dei nostri servizi</li>
            </ul>
            <p>
            L'applicazione potrà anche richiedere all’utente un consenso separato per raccogliere 
            le informazioni o avvisarlo separatamente sul modo in cui raccoglie i suoi dati personali 
            in maniera non descritta nella presente Informativa sulla privacy, come previsto per 
            alcuni servizi aggiuntivi. 
            </p>
            <ul>  
            <li>- Fornire i servizi richiesti dall’utente</li>
            <li>- Comprendere il modo in cui utilizza i servizi, al fine di migliorarli</li>
            </ul> 
            <p>Per ulteriori informazioni potete leggere l'informativa completa qui sotto</p>
            <h4>Titolare del trattamento</h4>
            <p>Il Titolare del trattamento ai sensi delle leggi vigenti è l'amministratore del sito, 
            contattabile tramite <a  href="mailto:ramirez@codigos.it"> link del contatto</a></p>
            <h4>Dati raccolti</h4>
            <p>
            Il presente sito raccoglie dati degli utenti in due modi:<br/>

            Dati raccolti in maniera automatizzata
            Durante la navigazione degli utenti possono essere raccolte le seguenti informazioni 
            attraverso i cookie che vengono conservate nei data dell'amministratore del sito. <br/>
            Questi dati sono utilizzati a fini di statistica e analisi, in forma esclusivamente 
            aggregata. L'indirizzo IP è utilizzato esclusivamente a fini di sicurezza e 
            statistica e non è incrociato con nessun altro dato. 
            <br/>
            L’eventuale utilizzo di Cookie o di altri strumenti di tracciamento da 
            parte di questa applicazione hanno la finalità di: 
            </p>
            <ul>
            <li>- Conoscere meglio i propri clienti al fine di offrire servizi e esperienze 
              più pertinenti.</li>
            <li>- Fornire contenuto e pubblicità personalizzate con il consenso specifico 
              dell’utente ove previsto.</li>
            </ul>
            <p>
            la descrizione dettagliata dei cookie, la trovi sulla nostra Cookie Policy in 
            fondo pagina.
L'Utente si assume la responsabilità dei dati personali di terzi ottenuti, pubblicati o 
condivisi mediante questa applicazione e garantisce di avere il diritto di comunicarli o 
diffonderli, liberando il Titolare da qualsiasi responsabilità verso terzi.
            </p>
            <br/>
            <h4>Modalità e luogo del trattamento dei dati raccolti</h4>
<h6>Modalità di trattamento</h6>
<p>
Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la 
divulgazione, la modifica o la distruzione non autorizzata dei dati personali. 
Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con 
modalità organizzative e con logiche strettamente correlate alle finalità indicate. 
Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai dati altri soggetti 
coinvolti nell’organizzazione di questa applicazione (personale amministrativo, 
commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni
(come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società 
informatiche, agenzie di comunicazione) nominati anche, se necessario, responsabili del 
trattamento da parte del Titolare. 
L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del
trattamento.
</p>
<h6>Base giuridica del trattamento</h6>
<p>
Il Titolare tratta dati Personali relativi all’utente in caso sussista una delle seguenti
 condizioni: 
l’utente ha prestato il consenso per una o più finalità specifiche
</p>
<ul>
<li>il trattamento è necessario all'esecuzione di un contratto con l’utente e/o all'esecuzione di 
  misure precontrattuali</li>
<li>il trattamento è necessario per adempiere un obbligo legale al quale è soggetto 
  il titolare</li>
<li>il trattamento è necessario per l'esecuzione di un compito di interesse 
  pubblico o per l'esercizio di pubblici poteri di cui è investito il titolare</li>
<li>il trattamento è necessario per il perseguimento del legittimo interesse del 
  titolare o di terzi</li>
</ul>
<p>Nota: in alcuni ordinamenti il Titolare può essere autorizzato a 
  trattare dati personali senza che debba sussistere il 
  consenso dell’Utente o un’altra delle basi giuridiche 
  specificate di seguito, fino a quando l’utente non si opponga 
  (“opt-out”) a tale trattamento. 
  Ciò non è tuttavia applicabile qualora il trattamento di dati personali
  sia regolato dalla legislazione europea in materia di protezione dei dati Personali.
</p>
<h6>Luogo</h6>
<p>
I dati sono trattati presso la sede operativa del Titolare. 
Il presente sito potrebbe condividere alcuni dei dati raccolti con servizi localizzati 
al di fuori dell'area dell'Unione Europea. In particolare con Google, Facebook e Microsoft
(LinkedIn) tramite i social plugin e il servizio di Google Analytics. Il trasferimento
è autorizzato in base a specifiche decisioni dell'Unione Europea e del Garante per la
tutela dei dati personali, in particolare la decisione 1250/2016
(Privacy Shield - qui la pagina informativa del Garante italiano), per cui non occorre 
ulteriore consenso. Le aziende sopra menzionate garantiscono la propria adesione al 
Privacy Shield.
</p>
<h6>Periodo di conservazione</h6>
<p>
I dati raccolti dal sito durante il suo funzionamento sono conservati per il tempo 
strettamente necessario a svolgere le attività precisate. Alla scadenza i dati saranno
cancellati o anonimizzati, a meno che non sussistano ulteriori finalità per la 
conservazione degli stessi.
I dati personali raccolti per scopi collegati all’esecuzione di un contratto tra il 
Titolare e l’utente saranno trattenuti sino a quando sia completata l’esecuzione di 
tale contratto.
</p>
<h4>Diritti dell'Utente</h4>
<p>
Ai sensi del Regolamento europeo 679/2016 (GDPR) e dell'art. 7 del D. Lgs. 30 giugno 2003, n. 196, l'Utente può, secondo le modalità e nei limiti previsti dalla vigente normativa, esercitare i deguenti diritti:
</p>
<ul>
<li>L'Utente può revocare il consenso al trattamento dei propri dati Personali precedentemente espresso.</li>
<li>L'utente può opporsi al trattamento dei propri dati quando esso avviene su una base giuridica diversa dal consenso.</li>
<li>L'utente ha diritto ad ottenere informazioni sui dati trattati dal Titolare, su determinati aspetti del trattamento ed a ricevere una copia dei dati trattati.</li>
<li>L'utente può verificare la correttezza dei propri dati e richiederne l’aggiornamento o la correzione.</li>
<li>L'utente può richiedere la limitazione del trattamento dei propri dati. In tal caso il Titolare non tratterà i dati per alcun altro scopo se non la loro conservazione.</li>
<li>L'utente può richiedere la cancellazione dei propri dati da parte del Titolare.</li>
<li>L'utente ha diritto di ricevere i propri dati in formato strutturato, di uso comune e leggibile da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli ad un altro titolare.</li>
<li>L'utente ha il diritto di presentare un reclamo all’Autorità di controllo (Garante Privacy – <a rel="noopener noreferrer" href="https://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/4535524" target="_blank" > link pagina del Garante </a>)</li>
</ul>
<p>
Per esercitare i diritti dell’utente, gli utenti possono indirizzare una richiesta
agli estremi di contatto del Titolare indicati in questo documento. Le richieste
sono depositate a titolo gratuito e evase dal Titolare nel più breve tempo possibile, 
in ogni caso entro un mese.
</p>
<h4>Ulteriori informazioni sul trattamento</h4>
<h6>Difesa in giudizio</h6>
<p>
I dati Personali dell’Utente possono essere utilizzati da parte del Titolare in giudizio
o nelle fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi
nell'utilizzo di questa applicazione o dei Servizi connessi da parte dell’Utente.
L’Utente dichiara di essere consapevole che il Titolare potrebbe essere obbligato a
rivelare i dati per ordine delle autorità pubbliche. 
</p>
<h6>Log di sistema e manutenzione</h6>
<p>
Per necessità legate al funzionamento ed alla manutenzione, questa applicazione e
gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, 
ossia file che registrano le interazioni e che possono contenere anche dati Personali,
quali l’indirizzo IP Utente. 
</p>
<h6>Informazioni non contenute in questa policy </h6>
<p>
Ulteriori informazioni in relazione al trattamento dei dati Personali potranno essere richieste in qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto.
</p>
<h4>Modifiche di questa Privacy Policy Cookie</h4>
<p>
Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento dandone informazione agli Utenti su questa pagina e, se possibile, su questa applicazione nonché, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di contatto di cui è in possesso il Titolare . Si prega dunque di consultare regolarmente questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo. 
Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il Titolare provvederà a raccogliere nuovamente il consenso dell’Utente, se necessario. 
Definizioni e riferimenti legali.
<br/> Ultima modifica: 21 Ottobre 2019.
</p>
<h4>Normativa di riferimento</h4>
<p>
REGOLAMENTO (UE) 2016/679 del Parlamento Europeo e Consiglio, del 27 aprile 2016, relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla libera circolazione di tali dati e che abroga la direttiva 95/46/CE (regolamento generale sulla protezione dei dati). Decreto Legislativo 196/2003, codice in materia di protezione dei dati personali. Provvedimento del Garante della Privacy n. 229/2014, relativo alla "Individuazione delle modalità semplificate per l'informativa e l'acquisizione del consenso per l'uso dei cookie.".
</p>
<h2>Cookie Policy</h2>               
            <p>Questa Cookie Policy ha lo scopo di rendere note le procedure seguite per la raccolta, tramite i cookie e/o altre tecnologie di monitoraggio, delle informazioni fornite dagli utenti quando visitano questo sito</p>  
            <h4>informazioni generali sui cookie</h4>
            <p>
            I cookies sono dei file che immagazzinano le informazioni sugli hard drive o sul browser, e che Permettono ai siti di controllare se lei ha già visitato il sito. I cookies ci consentono di capire quali sono le pagine del sito più visitate in quanto consentono di vedere quali pagine sono visitate e per quanto tempo. Attraverso questi dati riusciamo a rendere il sito più aderente alle sue richieste e più semplice la navigazione. Ad esempio, i cookies ci consentono di assicurarle che le informazioni presenti sul sito nelle sue future visite rispondano alle sue preferenze.
            Questo sito e le comunicazioni generate con l’uso e/o la registrazione su questo sito, utilizza i cookie per memorizzare informazioni sulle preferenze dei visitatori, per registrare informazioni specifiche dell'utente su quali pagine il visitatore del sito accede o visita, e personalizzare i contenuti della nostra pagina web in base ai visitatori ' tipo di browser o altre informazioni che il visitatore invia tramite il proprio browser. Non scambiamo cookie con siti esterni o fornitori esterni di dati.
            </p>              
            <h4>informazioni generali sulle normative al consenso del'uso dei cookie</h4>
            <p>
            La legislazione italiana (art. 122 del D.Lgs. 196/03 “Codice in materia di protezione dei dati personali” di seguito brevemente “Codice”) ha introdotto nel nostro ordinamento una Direttiva Europea che impone ai gestori dei siti web che utilizzano cookie o altre tecnologie di monitoraggio di informare l’utente circa le tipologie di eventuali cookie utilizzati dal sito. 
            Il Provvedimento del Garante per la protezione dei dati personali n. 229 del 8 maggio 2014, pubblicato sulla G.U. n. 126 del 3 giugno 2014, ha categorizzato i cookie in due macro-categorie: cookie "tecnici" e cookie "di profilazione".   
            </p>
            <h6>Cookie tecnici</h6>
            <p>           
            I cookie tecnici sono quelli utilizzati al solo fine di "effettuare la trasmissione di una comunicazione su una rete di comunicazione elettronica, o nella misura strettamente necessaria al fornitore di un servizio della società dell'informazione esplicitamente richiesto dall'abbonato o dall'utente a erogare tale servizio" (cfr. art. 122, comma 1, del Codice).Essi non vengono utilizzati per scopi ulteriori e sono normalmente installati direttamente dal titolare o gestore del sito web. Possono essere suddivisi in cookie di navigazione o di sessione, che garantiscono la normale navigazione e fruizione del sito web; cookie analytics, assimilati ai cookie tecnici laddove utilizzati direttamente dal gestore del sito per raccogliere informazioni, in forma aggregata, sul numero degli utenti e su come questi visitano il sito stesso; cookie di funzionalità, che permettono all'utente la navigazione in funzione di una serie di criteri selezionati al fine di migliorare il servizio reso allo stesso. 
            Per l'installazione di tali cookie non è richiesto il preventivo consenso degli utenti, mentre resta fermo l'obbligo di dare l'informativa ai sensi dell'art. 13 del Codice, che il gestore del sito, qualora utilizzi soltanto tali dispositivi, potrà fornire con le modalità che ritiene più idonee.
            </p>
            <h6>Cookie di profilazione</h6>
            <p>
            I cookie di profilazione sono volti a creare profili relativi all'utente e vengono utilizzati al fine di inviare messaggi pubblicitari in linea con le preferenze manifestate dallo stesso nell'ambito della navigazione in rete. In ragione della particolare invasività che tali dispositivi possono avere nell'ambito della sfera privata degli utenti, la normativa europea e italiana prevede che l'utente debba essere adeguatamente informato sull'uso degli stessi ed esprimere così il proprio valido consenso. 
            Ad essi si riferisce l'art. 122 del Codice laddove prevede che "l'archiviazione delle informazioni nell'apparecchio terminale di un contraente o di un utente o l'accesso a informazioni già archiviate sono consentiti unicamente a condizione che il contraente o l'utente abbia espresso il proprio consenso dopo essere stato informato con le modalità semplificate di cui all'articolo 13, comma 3" (art. 122, comma 1, del Codice). 
            Nel medesimo Provvedimento il Garante ha inoltre categorizzato i cookie a seconda del soggetto che opera in qualità di titolare del trattamento dei dati personali raccolti dal cookie, distinguendo fra cookie di prima parte e cookie di terza parte.
            </p>   
            <h6>Cookie di prima parte</h6>
            <p>           
            Sono i cookie gestiti dal titolare del sito. Per questi cookie, l’obbligo dell’informativa spetta al titolare del sito. Spetta anche a quest’ultimo l’obbligo di indicare le modalità per l’eventuale blocco del cookie.
            </p>
            <h6>Cookie di terze parti</h6>
            <p>
            Sono i cookie gestiti da un soggetto terzo diverso dal titolare del sito. Per questi cookie, l’obbligo dell’informativa e dell’indicazione delle modalità per l’eventuale blocco del cookie spetta alla terza parte, mentre al titolare del sito è fatto obbligo di inserire nel sito il link al sito della terza parte ove tali elementi sono disponibil
            </p>
            <p>
            In entrambe le tipologie di cookie (di prima parte o di terza parte) la raccolta del consenso, necessario qualora il cookie sia un cookie di profilazione, avviene tramite apposito banner nella home page del sito. Il presente sito non utilizza cookie di profilazione di terze parti
            </p>
            <h4>Cookie del sito KILOWATT-APP</h4>
            <p>
            In Cookie Policy è riportato l’elenco di tutti i cookie utilizzati da questo sito web. e sono riportate le seguenti informazioni per ciascun tipo di cookie:
            </p>
            <table> 
              <tbody>
                <tr><th>denominazione</th><th>Funzione</th><th>descrizione</th></tr>
               <tr>
                <td>Swa</td><td>session utente</td><td>cookie tecnico</td>
              </tr> 
              <tr>
                <td>Kes</td><td>session utente</td><td>cookie tecnico</td>
              </tr> 
              </tbody>        
            </table>
            <p>i cookie cosiddetti tecnici non richiedeno il consenso dell’utente.</p> 
            <h6>Durata dei cookie</h6>
            <p>
            Alcuni cookie (cookie di sessione) restano attivi solo fino alla chiusura del browser o all'esecuzione dell'eventuale comando di logout. Altri cookie "sopravvivono" alla chiusura del browser e sono disponibili anche in successive visite dell'utente. Questi cookie sono detti persistenti e la loro durata è fissata dal server al momento della loro creazione. In alcuni casi è fissata una scadenza, in altri casi la durata è illimitata.
            </p>
            <h6>Gestione dei cookie</h6>
            <p>
            L'utente può decidere se accettare o meno i cookie utilizzando le impostazioni del proprio browser. Attenzione: con la disabilitazione totale o parziale dei cookie tecnici potrebbe compromettere l'utilizzo ottimale del sito. 
            La disabilitazione dei cookie "terze parti" non pregiudica in alcun modo la navigabilità. L'impostazione può essere definita in modo specifico per i diversi siti e applicazioni web. Inoltre i browser consentono di definire impostazioni diverse per i cookie "proprietari" e per quelli di "terze parti". A titolo di esempio, in Firefox, attraverso il menu Strumenti->Opzioni->Privacy, è possibile accedere ad un pannello di controllo dove è possibile definire se accettare o meno i diversi tipi di cookie e procedere alla loro rimozione. 
            In internet è facilmente reperibile la documentazione su come impostare le regole di gestione dei cookies per il proprio browser, a titolo di esempio si riportano alcuni indirizzi relativi ai principali browser:
            </p>   
            <h6>Chrome: <a rel="noopener noreferrer" target="_blank" href="https://support.google.com/chrome/answer/95647?hl=it ">link della pagina</a> </h6>
            <h6>Firefox: <a rel="noopener noreferrer" target="_blank" href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie "> link della pagina</a></h6>
            <h6>Internet Explorer: <a rel="noopener noreferrer" target="_blank" href="http://windows.microsoft.com/it-it/windows7/how-to-manage-cookies-in-internet-explorer-9"> link della pagina</a></h6>
            <h6>Opera: <a rel="noopener noreferrer" target="_blank" href="http://help.opera.com/Windows/10.00/it/cookies.html">link della pagina </a></h6>
            <h6>Safari: <a rel="noopener noreferrer" target="_blank" href="http://support.apple.com/kb/HT1677?viewlocale=it_IT"> link della pagina</a></h6>
            <h4>contatti</h4>
            <p>
             Per qualsiasi domanda, commento o dubbio relativo alla presente Cookie Policy o alla Privacy Policy del sito, contattare la Società aL seguene indirizzo mail ( link del contatto ).
            </p>
            <h4>link ad altri siti web</h4>
            <p>
              Questo sito potrebbe contenere link o riferimenti per l’accesso ad altri siti web. La Società non controlla i cookie / le tecnologie di monitoraggio di altri siti web ai quali la presente Cookie Policy non si applica.
            </p>

   </div>


</div>
            </div>
        </div>
      
    )
  }
}
export default PrivacyCookie