import React,{Component} from 'react'
import Snap from 'snapsvg-cjs'

class OptionsBusiness extends Component{
    constructor(props){
        super(props)
        this.bimestraleOption = this.props.periodo.bind(this)
        this.potenzaImpegnata = this.props.imp.bind(this)
        this.stateComponent = this.props.stato.bind(this)
        this.statePeriodoMensile = this.props.mensile.bind(this)
        this.statePeriodoBimestrale = this.props.bimestrale.bind(this)
        this.impPressPlus = this.impPressPlus.bind(this)
        this.impPressMenus = this.impPressMenus.bind(this)
        this.impReleasePlus = this.impReleasePlus.bind(this)
        this.impReleaseMenus = this.impReleaseMenus.bind(this)
        this.pressMensile = this.pressMensile.bind(this)
        this.pressBimestrale = this.pressBimestrale.bind(this)
        this.state = {
            stateComponent : false,
            settingsDisplay : false,
            handlePeriodo: false,
            statoCalendar: false,
            handleImpegnata: false,
            statoCounterImp: false,
            statoMensile: false,
            stateBimestrale: false,
            potenza: this.props.power,
            impegnata: 0
        }
    }
    componentDidMount =() =>{
        const rectPotenza = Snap("#rect-potenza")
        const impegnataCounter = Snap("#impegnata-counter")
        const industryOne = Snap("#industry-one")  
        const industryOneBase = industryOne.select("#industry-one-base")
        const industryOneRoof = industryOne.select("#industry-one-roof")
        const industryOneDoorOne = industryOne.select("#industry-one-door-1")
        const industryOneDoorTwo = industryOne.select("#industry-one-door-2")
        const industryOneWindowOne = industryOne.select("#industry-one-window-1")
        const industryOneWindowTwo = industryOne.select("#industry-one-window-2")
        const industryOneWindowThree = industryOne.select("#industry-one-window-3")
        const industryTwo = Snap("#industry-two")
        const industryTwoBase = industryTwo.select("#industry-two-base")
        const industryTwoRoof = industryTwo.select("#industry-two-roof")
        const industryTwoWindowOne = industryTwo.select("#industry-two-window-1")
        const industryTwoWindowTwo = industryTwo.select("#industry-two-window-2")
        const industryTwoWindowThree = industryTwo.select("#industry-two-window-3")
        const industryTwoWindowFour = industryTwo.select("#industry-two-window-4")
        const industryThree = Snap("#industry-three")
        const industryThreeBase = industryThree.select("#industry-three-base")
        const industryThreeRoof = industryThree.select("#industry-three-roof")
        const industryThreeDoor = industryThree.select("#industry-three-door")
        const industryThreeWindow = industryThree.select("#industry-three-window")
        const industryThreeChimney = industryThree.select("#industry-three-chimney")
        const gas = Snap("#gas")
        const bigCircle = Snap("#bigCircle")
        gas.attr({opacity:0})
        rectPotenza.attr({ transform:'t-300,0'})
        impegnataCounter.attr({transform:'t0,-200'})
        
        ini()
        this.gasShow()
        this.handleCards()

        function ini(){
          bigCircle.animate(industries(), 400, window.mina.bounce, function(){
              bigCircle.animate({'r': bigCircle.attr('data-r')}, 400, window.mina.bounce,function(){
                gas.animate({opacity: '.90'}, 500, window.mina.linear)
              }) 
                      
        })
           
        }
        function industries(){
           industryOneShow()
           industryTwoShow()
           industryThreeShow()
        }
        function industryOneShow(){
            industryOneBase.animate({'height': industryOneBase.attr('data-height')},400, window.mina.bounce, function(){
                industryOneRoof.animate({'height': industryOneRoof.attr('data-height')}, 400, window.mina.bounce, function(){
                    industryOneDoorOne.animate({'height':industryOneDoorOne.attr('data-height')}, 400, window.mina.bounce)
                    industryOneDoorTwo.animate({'height':industryOneDoorTwo.attr('data-height')}, 400, window.mina.bounce)
                    industryOneWindowOne.animate({'width':industryOneWindowOne.attr('data-width')}, 400, window.mina.bounce)
                    industryOneWindowTwo.animate({'width':industryOneWindowTwo.attr('data-width')}, 400, window.mina.bounce)
                    industryOneWindowThree.animate({'width':industryOneWindowThree.attr('data-width')}, 400, window.mina.bounce) 
                })
            })
        }
        function industryTwoShow(){
            industryTwoBase.animate({'height': industryTwoBase.attr('data-height')}, 400, window.mina.bounce , function(){
                industryTwoRoof.animate({'height': industryTwoRoof.attr('data-height')}, 400, window.mina.bounce, function(){
                    industryTwoWindowOne.animate({'width': industryTwoWindowOne.attr('data-width')}, 400, window.mina.bounce)
                    industryTwoWindowTwo.animate({'width': industryTwoWindowTwo.attr('data-width')}, 400, window.mina.bounce)
                    industryTwoWindowThree.animate({'width': industryTwoWindowThree.attr('data-width')}, 400, window.mina.bounce)
                    industryTwoWindowFour.animate({'width': industryTwoWindowFour.attr('data-width')}, 400, window.mina.bounce)
                })
            })
        }
        function industryThreeShow(){
            industryThreeBase.animate({'height': industryThreeBase.attr('data-height')}, 400, window.mina.bounce, function(){
                industryThreeRoof.animate({'height': industryThreeRoof.attr('data-height')}, 400, window.mina.bounce, function(){
                    industryThreeDoor.animate({'height': industryThreeDoor.attr('data-height')}, 400, window.mina.bounce)
                    industryThreeWindow.animate({'width': industryThreeWindow.attr('data-width')}, 400, window.mina.bounce)
                    industryThreeChimney.animate({height: industryThreeChimney.attr('data-height')}, 400, window.mina.bounce)
                  
                })

            })
        }
    }

    pressMensile = () => {
        if(!this.state.handlePeriodo){  
        this.setState({ handlePeriodo: true, statoMensile: true })
        const mensile= Snap("#mensile")
        mensile.animate({transform:'s1.2'}, 400, window.mina.linear)
        this.statePeriodoMensile(true) 
        }else{
        this.unPressMensile() 
        this.unPressBimestrale()  
        }
             
    }
    unPressMensile = () => {
        if(this.state.handlePeriodo){
        this.setState({ handlePeriodo: false })    
        const mensile = Snap("#mensile")
        mensile.animate({transform: 's1'}, 400, window.mina.linear)
        this.statePeriodoMensile(false)   
        }
        
    }

    pressBimestrale = () => {
        if(!this.state.handlePeriodo){
        this.setState({ handlePeriodo: true })
        const bimestrale = Snap("#bimestrale")
        bimestrale.animate({transform: 's1.2'}, 400, window.mina.linear, function(){
        })
        this.statePeriodoBimestrale(true)   
        }else{
        this.unPressBimestrale()    
        this.unPressMensile()
        
        }
        
    }
    unPressBimestrale = () => {
        if(this.state.handlePeriodo){
        this.setState({ handlePeriodo: false })    
        const bimestrale = Snap("#bimestrale")
        bimestrale.animate({transform: 's1'}, 400, window.mina.linear)
        this.statePeriodoBimestrale(false)     
        }
        
    }
    handleCards = () => {
        if(this.state.potenza <= 15){
            this.showPeriodo()   
            this.removeImpegnata() 
            }else{
            this.removePeriodo()
            this.showImpegnata()    
            }
    }
   
    showPeriodo = () => {
        const periodoMensile = Snap("#periodo-mensile")
        const periodoBimestrale =Snap("#periodo-bimestrale")
        const bimestrale = Snap("#calendar-bimestrale")
        const mensile = Snap("#calendar-mensile")
        const bimestraleLine = Snap("#calendar-bimestrale-line")
        const mensileLine = Snap("#calendar-mensile-line")
        const bimestraleCircleOne = Snap("#calendar-bimestrale-circle-one") 
        const bimestraleCircleTwo = Snap("#calendar-bimestrale-circle-two")
        const mensileCircle = Snap("#calendar-mensile-circle")   
            periodoMensile.animate({ opacity:'1'},400, window.mina.bounce,function(){
            mensile.animate({'width': mensile.attr('data-width')}, 300, window.mina.bounce)
            mensileLine.animate({ 'width': mensileLine.attr('data-width')}, 400, window.mina.bounce)
            mensileCircle.animate({'r': mensileCircle.attr('data-r')}, 400 , window.mina.bounce)
            
        })
            periodoBimestrale.animate({opacity: '1'}, 400 , window.mina.bounce, function(){
            bimestrale.animate({'width': bimestrale.attr('data-width')}, 300, window.mina.bounce)
            bimestraleLine.animate({ 'width': bimestraleLine.attr('data-width')}, 400, window.mina.bounce)
            bimestraleCircleOne.animate({'r': bimestraleCircleOne.attr('data-r')}, 400 , window.mina.bounce)
            bimestraleCircleTwo.animate({'r': bimestraleCircleTwo.attr('data-r')}, 400 , window.mina.bounce)
        })
    }
    removePeriodo = ()=>{
        const calendari = Snap("#calendari-ui")
        calendari.attr({transform: 't0,-200'})
    } 

    showImpegnata = () =>{
            const settingsDisplay = Snap("#settings-display")
            const rectPotenza = Snap("#rect-potenza")
            const impegnataCounter = Snap("#impegnata-counter")
            settingsDisplay.animate({ 'height': settingsDisplay.attr('data-height')}, 300 , window.mina.bounce, function(){
                impegnataCounter.animate({transform:'t0,0'}, 400, window.mina.bounce)
                rectPotenza.animate({ transform: 't0,0'}, 400, window.mina.bounce)  
            })
    }

    removeImpegnata = () =>{
    const settingsDisplay = Snap("#settings-display")
    const rectPotenza = Snap("#rect-potenza")
    const impegnataCounter = Snap("#impegnata-counter")
    rectPotenza.animate({ transform: 't-150,0'}, 400, window.mina.bounce)
    impegnataCounter.animate({ transform: 't0,-200'}, 400, window.mina.bounce, function(){
    settingsDisplay.animate({'height': 0 }, 600, window.mina.bounce)
    })
    }

    gasShow = () =>{
        const gas1 = Snap("#gas-1")
        const gas2 = Snap("#gas-2")
        const gas3 = Snap("#gas-3")
        function gasOne(){
             gas1.animate({strokeDashoffset: "12",opacity:"0"},2000, window.mina.linear,function(){
             gas1.attr({strokeDashoffset:0, opacity:".95"})    
             })
         }
         function gasDue(){
            gas2.animate({strokeDashoffset: "10",opacity:"0"},1500, window.mina.linear,function(){
            gas2.attr({strokeDashoffset:0, opacity:".95"})    
            })
        }
        function gasTre(){
            gas3.animate({strokeDashoffset: "10",opacity:"0"},1000, window.mina.linear,function(){
            gas3.attr({strokeDashoffset:0, opacity:".95"})    
            })
        }
       setInterval(()=>{
           gasOne()
           gasDue()
           gasTre()
       },3000)
    
    }
   
    handleImpegnata = () =>{   
        if(!this.state.handleImpegnata){
            this.setState({ handleImpegnata: true })
            this.potenzaImpegnata(this.state.impegnata)
        }else{
            this.potenzaImpegnata(0)
        }
    }

    impPressPlus = () =>{
    if(!this.state.statoCounterImp){
    this.impClickPlus()    
    this.pressImpTimerPlus = setInterval(()=>{
    this.setState({ impegnata: this.state.impegnata === 99 ? 99 : this.state.impegnata + 0.5 })
    },400)   
    }  
    }
    impReleasePlus = () => {
        this.removeImpClickPlus()
        clearInterval(this.pressImpTimerPlus)
    }
    impClickPlus = ()=>{
        const switchPlus = Snap("#imp-switch-plus")
        if(!this.state.statoCounterImp){
        switchPlus.animate({ transform: 's0.9'}, 400, window.mina.bounce)    
        }
        
    }
    removeImpClickPlus = () =>{
        const switchPlus = Snap("#imp-switch-plus")
        switchPlus.animate({ transform: 's1'}, 400, window.mina.bounce)
    }
    impPressMenus = ()=> {
        this.impClickMenus()
        if(!this.state.statoCounterImp){
        this.pressImpTimerMenus = setInterval(()=>{
        this.setState({ impegnata: this.state.impegnata === 0 ? 0 : this.state.impegnata - 0.5 })  
        },400)    
        } 
    }
    impReleaseMenus = () => {
        this.removeImpClickMenus()
        clearInterval(this.pressImpTimerMenus)
    }
    impClickMenus = ()=>{
        const switchMenus = Snap("#imp-switch-menus")
        if(!this.state.statoCounterImp){
        switchMenus.animate({ transform: 's0.9'}, 400, window.mina.bounce)    
        }
        
    }
    removeImpClickMenus = () =>{
        const switchMenus = Snap("#imp-switch-menus")
        switchMenus.animate({ transform: 's1'}, 400, window.mina.bounce)
    }
    handleClickIpmCheck =()=>{
        const switchCheck = Snap("#imp-switch-check")
        switchCheck.animate({ transform: 's0.95'}, 400, window.mina.bounce)
        this.setState({ statoCounterImp: true })
    }
    
    removeClickCheckImpegnata =()=>{
        const switchCheck = Snap("#imp-switch-check")
        switchCheck.animate({ transform: 's1'}, 400, window.mina.bounce)
        this.setState({ statoCounterImp: false })
    }
    handleCheckImpegnata =()=>{
        if(!this.state.statoCounterImp){
        this.handleClickIpmCheck()   
        this.stateComponent(true) 
        this.potenzaImpegnata(this.state.impegnata)
        }else{
        this.removeClickCheckImpegnata()
        this.stateComponent(false)
        this.potenzaImpegnata(0)
        }
        
    }
    render(){
    console.log(this.state)
    let lenPotenza = this.state.impegnata.toString()
    const isDesktop = /Windows|Macintosh|X11|Ubuntu|Linux/i.test(navigator.userAgent)
    const isMobile = /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent)
        return(
            <div className="Potenza">
                 <svg id="svg-business" 
                width="300" height="100"
                viewBox="0 0 300 100">
                    <circle id="bigCircle" cx="80" cy="60" r="0" data-r="40" fill="#fff124"/>
                   <g id="industries">
                        <g id="industry-two">
                         <rect  id="industry-two-base"x="50" y="30" width="35" height="0" data-height="60" fill="white"stroke="gray" strokeWidth="2"/>
                         <rect id="industry-two-roof" x="50" y="27" width="35" height="0" data-height="3" fill="gray" stroke="gray" strokeWidth="2"/>
                         <rect id="industry-two-window-1" x="55" y="35" width="0" data-width="8" height="5" fill="white"stroke="gray" strokeWidth="2"/>
                         <rect id="industry-two-window-2" x="70" y="35" width="0" data-width="8" height="5" fill="white"stroke="gray" strokeWidth="2"/>
                         <rect id="industry-two-window-3" x="55" y="47" width="0" data-width="8" height="5" fill="white"stroke="gray" strokeWidth="2"/>
                         <rect id="industry-two-window-4" x="70" y="47" width="0" data-width="8" height="5" fill="white"stroke="gray" strokeWidth="2"/>
                        </g>
                       <g id="industry-one">
                       <rect id="industry-one-base" x="30" y="60" width="50" height="0" data-height="30" fill="white" stroke="gray" strokeWidth="2"/>
                       <rect id="industry-one-roof" x="30" y="57" width="50" height="0" data-height="3"fill="gray" stroke="gray" strokeWidth="2"/>
                       <rect id="industry-one-door-1" x="36" y="75" width="20" height="0" data-height="15" fill="white" stroke="gray" strokeWidth="2"/>
                       <rect id="industry-one-door-2" x="64" y="80" width="10" height="0" data-height="10" fill="white"stroke="gray" strokeWidth="2"/>
                       <rect id="industry-one-window-1" x="36" y="65" width="0" data-width="8" height="5" fill="white"stroke="gray" strokeWidth="2"/>
                       <rect id="industry-one-window-2" x="51" y="65" width="0" data-width="8" height="5" fill="white"stroke="gray" strokeWidth="2"/>
                       <rect id="industry-one-window-3" x="66" y="65" width="0" data-width="8" height="5" fill="white"stroke="gray" strokeWidth="2"/>
                       </g>
                       <g id="industry-three" >
                           <g id="gas">
                        <path
                        id="gas-1"
                        stroke="black" strokeLinecap="round" strokeLinejoin="round"
                        strokeWidth="3" strokeDasharray="0.2 30" strokeMiterlimit="1"
                    
                        d="m97 65 v-15z"
                        /> 
                        <path
                        id="gas-2"
                        stroke="black" strokeLinecap="round" strokeLinejoin="round"
                        strokeWidth="2" strokeDasharray="0.2 26" strokeMiterlimit="1"
                        d="m99 63 v-13z"
                       
                        /> 
                        <path
                        id="gas-3"
                        stroke="black" strokeLinecap="round" strokeLinejoin="round"
                        strokeWidth="1" strokeDasharray="0.1 20" strokeMiterlimit="1"
                        d="m96.5 63 v-10z"
                   
                        /> 
                        </g>
                        <rect id="industry-three-base"x="90" y="70" width="40" height="0" data-height="20" fill="white" stroke="gray" strokeWidth="2"></rect>
                        <rect id="industry-three-roof" x="85" y="67" width="50" height="0"data-height="3" fill="gray" stroke="gray" strokeWidth="2"/>
                        <rect id="industry-three-door" x="115" y="80" width="10" height="0" data-height="10" fill="white" stroke="gray" strokeWidth="2"/>
                        <rect id="industry-three-window" x="95" y="77" width="0" data-width="15" height="10" fill="white" stroke="gray" strokeWidth="2"/>
                        <rect id="industry-three-chimney"x="95" y="62" width="6" height="0" data-height="4" fill="gray" stroke="gray" strokeWidth="2"/>
                        
                        
                        </g>
                   
                  </g> 
                   <g id="settings">
                       <rect id="settings-display" x="10" y="10" width="290"height="0" data-height="90" stroke="white" strokeWidth="2"  fill="white"/>
                      
                        <g id="calendari-ui">
                        <text x="208" y="17" fontWeight="bold" fontSize="12px" fill="gray">Opzioni</text>
                       <g id="bimestrale" onClick={this.pressBimestrale} >
                        <rect id="calendar-bimestrale" x="245" y="30" width="35" data-width="35" height="35" stroke="gray" strokeWidth="3" fill="transparent" strokeLinejoin="round" strokeLinecap="round"/>
                        <rect id="calendar-bimestrale-line" x="245" y="32" width="0" data-width="33" height="4" stroke="gray" strokeWidth="2" fill="gray"/>
                        <circle id="calendar-bimestrale-circle-one" cx="253" cy="43" r="0" data-r="4" fill="gray" />
                        <circle id="calendar-bimestrale-circle-two" cx="263" cy="43" r="0" data-r="4" fill="gray" />
                        <text id="periodo-bimestrale" x="242" y="80" fontSize="9" fill="gray" opacity="0">Bimestrale</text>
                       </g>
                       <g id="mensile" onClick={this.pressMensile}>
                        <rect id="calendar-mensile" x="180" y="30" width="35" data-width="35" height="35" stroke="gray" strokeWidth="3" fill="transparent" strokeLinejoin="round" strokeLinecap="round"/>
                        <rect id="calendar-mensile-line" x="180" y="32" width="0" data-width="33" height="4" stroke="gray" strokeWidth="2" fill="gray"/>
                        <circle id="calendar-mensile-circle" cx="188" cy="43" r="0" data-r="4" fill="gray" />
                        <text id="periodo-mensile" x="182" y="80" fontSize="9" fill="gray" opacity="0">Mensile</text>

                       </g>
                       </g>
                        <g id="rect-potenza">
                            <circle cx="45" cy="35" r="35" fill="#fff124"/>
                            <rect id="potenza-impegnata" x="38" y="22" width="45" height="45" fill="white" stroke="gray" strokeWidth="3"/>
                            <text id="potenza-impegnata-text-one" x="52" y="40" fontSize="12"fill="gray">KW</text>
                            <text id="potenza-impegnata-text-two" x={lenPotenza.length  === 1 ? "57" : "50"} y="58" fontWeight="bold" fontSize="16" fill="black">{this.state.impegnata}</text> 
                            <text id="impe" x="45"y="80" fontSize="10" fill="#616161">Potenza</text>
                            <text id="impe" x="40"y="90" fontSize="10" fill="#616161">Impegnata</text>
                            </g>

                        <g id="impegnata-counter">
                                    <g id="box-counter" >
                                    <rect id="rect-counter" x="125" y="10" width="164" height="80" stroke="gray" strokeWidth="2" fill="white" strokeLinejoin="round"/>
                                    { 
                                        isDesktop &&
                                        <g id="imp-switch-menus"
                                        onMouseEnter={this.impPressMenus}
                                        onMouseOut={this.impReleaseMenus}>
                                        <rect id="box-imp-switch-menus" x="144" y="20" width="40" height="50" stroke="#C6C3C7" strokeWidth="2" fill="white"></rect>
                                        <path id="menus-imp-path" stroke="#616161" strokeWidth="2" fill="#616161" strokeLinejoin="round" d="m157 57 h12 v3 h-12 v-3z" />
                                        </g>
                                    }
                                    {
                                        isMobile &&
                                        <g id="imp-switch-menus"
                                        onTouchStart={this.impPressMenus}
                                        onTouchEnd={this.impReleaseMenus}>
                                        <rect id="box-imp-switch-menus" x="144" y="20" width="40" height="50" stroke="#C6C3C7" strokeWidth="2" fill="white"></rect>
                                        <path id="menus-imp-path" stroke="#616161" strokeWidth="2" fill="#616161" strokeLinejoin="round" d="m157 57 h12 v3 h-12 v-3z" />
                                        </g>
                                    }
                                    {
                                        isDesktop &&
                                        <g id="imp-switch-plus" 
                                        onMouseEnter={this.impPressPlus}
                                        onMouseOut={this.impReleasePlus}>
                                        <rect id="box-imp-switch-plus" x="187" y="20" width="40" height="50" stroke="#C6C3C7" strokeWidth="2" fill="white"></rect>
                                        <path id="plus-imp-path" fill="#616161"stroke="#616161" strokeWidth="3"  strokeLinejoin="round" d="m206 52 h2 v5 h5v2 h-5v5 h-2 v-5h-5v-2h5v-5z" />
                                        </g>
                                    }
                                    {
                                        isMobile &&
                                        <g id="imp-switch-plus" 
                                        onTouchStart={this.impPressPlus}
                                        onTouchEnd={this.impReleasePlus}>
                                        <rect id="box-imp-switch-plus" x="187" y="20" width="40" height="50" stroke="#C6C3C7" strokeWidth="2" fill="white"></rect>
                                        <path id="plus-imp-path" fill="#616161"stroke="#616161" strokeWidth="3"  strokeLinejoin="round" d="m206 52 h2 v5 h5v2 h-5v5 h-2 v-5h-5v-2h5v-5z" />
                                        </g>
                                    }
                                    <g id="imp-switch-check" 
                                    onClick={this.handleCheckImpegnata}>
                                    <rect  id="imp-switch-check"  x="230" y="20" width="40" height="50" stroke="#C6C3C7" strokeWidth="2" fill="white"></rect>
                                    <path id="check-imp-path" strokeWidth="2" stroke="#666b68" strokeLinejoin="round" fill="#666b68" 
                                    d="m246 55l 3 4 l 5 -5 l2 2 l-7 7l-5 -6l 2 -2z" />
                                    <circle cx="249" cy="33" r="4" fill={this.state.statoCounterImp ? '#22FF50': 'white'} stroke="#666b68"/>
                                </g>
                                    <text id="box-imp-text" x="186" y="83" fontSize="11" fill="#666b68">premere</text>
                                    </g> 
                            </g>
                    </g>
                  
                </svg>
            </div>
        )
    }
}
export default OptionsBusiness
