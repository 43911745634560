import React, {Component} from 'react'
import Snap from 'snapsvg-cjs'

class HomeOptions extends Component {
    constructor(props){
        super(props)
        this.handleResident = this.props.click.bind(this)
        this.quotaFissa = this.props.quo
        this.state = {
            statusOption: false,
            option: null,
            fissa: this.quotaFissa
        }
    }
    componentDidMount =() => {
        const Svg = Snap("#svg")
        const home = Snap("#home")
        const resident = Snap("#resident")
        const noResident = Snap("#noResident")
        const iconlocation = resident.select("#icon-location")
        const iconNoLocation = noResident.select("#icon-nolocation")
        const panelTv = Snap("#canonetv")
        const tvBase = panelTv.select("#tv-base")
        const tv = panelTv.select("#tv")
        const panelOther = Snap("#other")
        const otherBase = panelOther.select("#other-base")
        home.attr({ 'opacity':'.3'})
        Svg.animate({ width: '300'}, 300, window.mina.linear)
        tv.attr({ transform: 't0,-100', opacity:'0'})
        panelOther.attr({transform: 't0,-100', opacity:'0'})

    resident.click(()=> {
        if(!this.state.statusOption){
        iconlocation.animate({transform: 's1.3 t0,-10'}, 300, window.mina.linear, this.showHome())
        showPanelTv()   
        this.handleResident('trueResidente')
        }else{
        iconlocation.animate( this.removeHome(),100, window.mina.linear, function(){
        iconlocation.animate({transform: 's1 t0,0'},1500, window.mina.linear)
        removePaneltv()
        })
        this.handleResident('falseResidente')
        }
    })    
    noResident.click(()=>{
        if(!this.state.statusOption){
        iconNoLocation.animate({transform: 's1.3 t0,-10'},300, window.mina.bounce)
        showOtherPanel()   
        this.handleResident('trueNonResidente')  
        }else{
        iconNoLocation.animate({transform: 's1 t0,0'},1500, window.mina.linear)
        removePanelOther()  
        this.handleResident('falseNonResidente')  
        }
    })
  
    
    function showPanelTv(){
        tvBase.animate({ height: tvBase.attr('data-height')},300, window.mina.bounce, function(){
        tv.animate({transform:'t0,0',opacity:'1'},400, window.mina.bounce)
        })       
    }
    function removePaneltv(){
        tv.animate({ transform: 't0,-100'}, 400 ,window.mina.bounce, function(){
            tvBase.animate({ 'height': 0}, 400, window.mina.bounce)
        })
    }
    function showOtherPanel(){
        otherBase.animate({ height: otherBase.attr('data-height')}, 400, window.mina.bounce, function(){
        panelOther.animate({transform: 't0,0', opacity:'1'},400 , window.mina.bounce)
        })    
    }
    function removePanelOther(){
        panelOther.animate({ transform:'t0,-100', opacity:'0'}, 400, window.mina.bounce, function(){
          otherBase.animate({'height': 0 }, 400, window.mina.bounce)  
        })
    }

    }//fine componentdidmount
    showHome = ()=>{
        const home = Snap("#home")
        const homeChimney = home.select("#home-chimney")
        const homeRoof = home.select("#home-roof")
        const homeBase = home.select("#home-base")
        const homeDoor = home.select("#home-door")
        const homeWindowOne = home.select("#home-window-1")
        const homeWindowTwo = home.select("#home-window-2")
        home.animate({transform: 't-50,0'}, 400, window.mina.linear, function(){
        homeBase.animate({ 'height': homeBase.attr('data-height') }, 300, window.mina.bounce, function(){
        homeDoor.animate({ 'height': homeDoor.attr('data-height')}, 300, window.mina.bounce )
        homeRoof.animate({'height': homeRoof.attr('data-height')}, 300, window.mina.bounce)
        homeChimney.animate({ 'height': homeChimney.attr('data-height')},300, window.mina.bounce)
        homeWindowOne.animate({ 'height': homeWindowOne.attr('data-height')}, 300, window.mina.bounce)
        homeWindowTwo.animate({ 'height': homeWindowTwo.attr('data-height')}, 300, window.mina.bounce)
        })
        })
        
    }
    removeHome = () =>{
        const home = Snap("#home")
        const homeChimney = home.select("#home-chimney")
        const homeRoof = home.select("#home-roof")
        const homeBase = home.select("#home-base")
        const homeDoor = home.select("#home-door")
        const homeWindowOne = home.select("#home-window-1")
        const homeWindowTwo = home.select("#home-window-2")
        homeWindowOne.animate({'height': 0}, 300, window.mina.bounce)
        homeWindowTwo.animate({'height': 0}, 300, window.mina.bounce)
        homeChimney.animate({'height': 0},300, window.mina.bounce)
        homeRoof.animate({'height': 0}, 300, window.mina.bounce)
        homeDoor.animate({'height': 0}, 300, window.mina.bounce, function(){
        homeBase.animate({'height': 0}, 400, window.mina.bounce ,function(){
            home.animate({transform:'t0,0'},300, window.mina.linear)
        })
        }) 
       
    }
    render(){     
        return(
            <div className="homeOptions" >
            <svg 
            id="svg"
            data-width="300" width="0" height="100" viewBox="0 0 300 100"> 
            <g id="home" >
                <rect
                id="home-chimney"
                x="130.5" y="15" width="6" height="0" data-height="6" fill="#ADB5FF"/>
                <rect
                id="home-roof"               
                x="129" y="21.5" width="38" height="0" data-height="4" fill="#8F8EFF"
                />
                <rect
                id="home-base"
                stroke="#8F8EFF" strokeWidth="3" 
                 x="130.5" y="27" width="35" height="0" data-height="35" fill="#ADB5FF" />
                <rect
                id="home-door"
                fill="white" stroke="#8F8EFF" strokeWidth="3" 
                x="142.5"y="47"width="12" height="0" data-height="15" />
                <rect
                id="home-window-1"
                fill="white" stroke="#8F8EFF" strokeWidth="3" 
                x="135.5"y="32"width="10" height="0" data-height="7"/>
                <rect
                id="home-window-2"
                fill="white" stroke="#8F8EFF" strokeWidth="3" 
                x="150.5"y="32"width="10" height="0" data-height="7" />
            </g>
                
            <g id="resident" onClick={() => { !this.state.statusOption ? this.setState({ statusOption: true,  option: 'resident'}) :  this.setState({ statusOption: false,  option: ''}) }} >
                <g id="icon-location" >
                    <path 
                    fill="#5ECCAD"
                    d="m 65 55 l 10 13 l 10 -13 a 16 16 0 0 0 3 -9 a 1 1 0 0 0 -26 0 a 16 16 0 0 0 3 9z"/>
                    <circle cx="75" cy="48" r="6" fill="white"></circle>      
                </g>
                <text x="46" y="84" fill="#666b68" fontSize="14">Residente</text>
            </g>

            <g id="other">
                <rect 
                id="other-base"
                x="28" y="10" width="80" height="0" fill="white" data-height="90" />
                <g id="other-cost">
                <text x="42" y="50" fill="#616161cb" fontStyle="18" fontWeight="bold">QFi</text>
                <text id="text-other" x="40" y="63" fill="#616161cb" fontSize="12">€ {this.state.fissa.toFixed(4).replace('.',',')}</text>
                </g>
            </g>
          

            <g id="noResident" onClick={() => { !this.state.statusOption ? this.setState({ statusOption: true,  option: 'noResident'}) :  this.setState({ statusOption: false,  option: ''}) }}>
                    
                    <g id="icon-nolocation" >            
                    <path 
                    fill="#78A2B3"
                    d="m 220 55 l 10 13 l 10 -13 a 16 16 0 0 0 3 -9 a 1 1 0 0 0 -26 0 a 16 16 0 0 0 3 9z"/>
                    <path 
                    stroke="white"
                    strokeWidth="3"
                    strokeLinejoin="round" strokeLinecap="round"
                    d="m 226 43 l 8 8 m -8 0 l 8 -8z"/>
                    </g> 
                    <text x="181" y="84" fill="#666b68" fontSize="14">Non Residente</text>
                
            </g>
            <g id="canonetv" >
                    <rect id="tv-base"
                    x="180" y="0" width="100" height="0" fill="white" data-height="90" />
                    <g id="tv">
                    <rect x="214" y="20" width="40" height="23" stroke="gray" fill="white" strokeWidth="3"/>
                    <rect x="229" y="46" width="13" height="3" fill="gray"/>
                    <text id="text-one" x="200" y="62" fill="#616161cb" fontSize="14">Canone TV</text>
                    <text id="text-two" x="200" y="73" fill="#616161cb"fontSize="12">€ 9,00/mese</text>
                    </g>
            </g> 
                        
            <text 
            id="textIni"
            x="100" y="98" fill="#616161cb" fontSize="10px" opacity="0" data-o="1">seleziona una opzione</text>
            </svg>  
           
            </div>
        )
    }
}

export default HomeOptions