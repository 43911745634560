import React,{Component} from 'react'
import Snap from 'snapsvg-cjs'

class ImagePage extends Component{
    constructor(props){
        super(props)
        this.state = {
            stato: false
        }
    }
    componentDidMount = () => {
        const circleOut = Snap("#circle-out")
        const boxLight = Snap("#box-light")
        const circleIn = boxLight.select("#circle-in")
        boxLight.attr({ transform: 't0,-150'})
        init()
        function init(){
            boxLight.animate({ transform: 't0,0'}, 800, window.mina.bounce, showCircle)
        }
        function showCircle(){
        circleOut.animate({ strokeDashoffset: "400", strokeOpacity:'1'},2000, window.mina.linear,function(){
            circleOut.animate({ strokeDashoffset: "440", strokeOpacity: '0'}, 400, window.mina.linear)
            circleIn.animate({ 'r': circleIn.attr('data-r')}, 1000, window.mina.linear)
        })}
        
        
    }
    render(){
        return(
            <div className="Image-intro">
            <svg width="300" height="150"
            viewBox="0 0 300 100">
                <path 
                id="circle-out"
                stroke="#ffd700" strokeWidth="10" fill="none" strokeOpacity="0"
                strokeMiterlimit="1" strokeDasharray="10 430" strokeLinecap="round" strokeLinejoin="round"
                d="m210 53 a 1 1 0 0 1 -120 0 a 1 1 0 0 1 120 0"/>
                
                <g id="box-light">
                <path 
                id="light"
                fill="white" 
                stroke="#a09e9e" strokeWidth="4"
                d="m 120 44 a -1 -1 0 0 1 60 0 c 0 0 0 12 -12 23 l -6 16 h -24 l -6 -16 c 0 0 -10 -6 -12 -23"
                />
                <path
                id="light-on"
                fiil="white" stroke="#a09e9e" strokeWidth="4"
                d="m 145 85 v-30z m0 -22 h10z m 10 -8 v30 "/>
                <circle id="circle-in" cx="150" cy="42" data-r="12" r="0" fill="#ffd700"/>
                <line x1="138" x2="162" y1="88" y2="88" strokeWidth="3" stroke="#a09e9e"/>
                <line x1="140" x2="160" y1="93" y2="93" strokeWidth="3" stroke="#a09e9e"/>
                </g>
            </svg>
            </div>
        )
    }
}
export default ImagePage