import React,{Component} from 'react'
import Snap from 'snapsvg-cjs'

class BoxRender extends Component{
    constructor(props){
        super(props)
        this.state = {
          statoComponent: true
        }
    }
    componentDidMount =() => {
        const bolletta = Snap("#bolletta")
        const boxBaseOne = bolletta.select("#box-base-1")
        const boxBaseTwo = bolletta.select("#box-base-2")
        const boxLineOne = bolletta.select("#box-line-1")
        const boxLineTwo = bolletta.select("#box-line-2")
        const boxLineThree = bolletta.select("#box-line-3")
        const boxCircle = bolletta.select("#box-circle")
        const light = Snap("#light")
    boxBaseOne.attr({transform: 't0,100'})
    boxBaseTwo.attr({transform: 't0,100', opacity: "0"})
    light.attr({transform: "t-50,0"})
    init()

    function init(){
        boxBaseOne.animate({ transform: 't0,0'}, 400, window.mina.bounce, function(){
            boxBaseTwo.attr({ transform: 't-10,0'})
            boxLineOne.animate({ 'x2': boxLineOne.attr('data-x2'), strokeOpacity:".9"}, 400, window.mina.bounce)
            boxLineTwo.animate({ 'x2': boxLineTwo.attr('data-x2'), strokeOpacity:".9"}, 600, window.mina.bounce)
            boxLineThree.animate({ 'x2': boxLineThree.attr('data-x2'), strokeOpacity:".9"}, 600, window.mina.bounce, function(){
                boxCircle.animate({ 'r': boxCircle.attr('data-r')}, 400, window.mina.bounce, ligthShow)
            })
        })
    function ligthShow(){
        light.animate({ transform: 't0,0'}, 600, window.mina.bounce)
        boxBaseTwo.animate({ opacity:"1", transform:"t0,0"}, 400, window.mina.bounce)
    }
    }

    }
    render(){
        return(
            <div> 
            <svg id="bolletta" viewBox="0 0 100 100" width="100px">
            
            <g id="box-bolletta">
                <rect id="box-base-2" stroke="gray" strokeWidth="1"
                strokeLinecap="round" strokeLinejoin="round"
                x="20" y="5" width="70" height="80"  fill="white" />
                <rect id="box-base-1" stroke="#cacaca" strokeWidth="3"
                strokeLinecap="round" strokeLinejoin="round"
                x="15" y="10" width="70" height="80"  fill="white" />
                
                <circle id="box-circle" cx="70" cy="25" r="0" data-r="6" stroke="#cacaca" strokeWidth="4" fill="white" />
            
            <line id="box-line-1" x1="30" x2="30" data-x2="70" y1="50" y2="50" fill="white" stroke="#cacaca"
             strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeOpacity="0"/>
             <line id="box-line-2" x1="30" x2="30" data-x2="60" y1="60" y2="60" fill="white" stroke="#cacaca"
             strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeOpacity="0"/>
             <line id="box-line-3" x1="30" x2="30" data-x2="70" y1="70" y2="70" fill="white" stroke="#cacaca"
             strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeOpacity="0"/>
            </g>
            <g id="light">
                <circle cx="20" cy="20" r="20" fill="#ffd700"/>
                <text x="12" y="28" fontSize="24px" fontWeight="bold" fill="gray">€</text>
            </g>
            </svg>
            
            </div>
      
        )
    }
}
export default BoxRender