class BollettaBta {
    constructor(utenza, rete){
        this.utenza = utenza
        this.currentMonth = rete.currentMonth
        this.lastMonth = rete.lastMonth
        this.f1 = (this.utenza.consumo /100 ) * 45
        this.f2 = (this.utenza.consumo /100 ) * 25
        this.f3 = (this.utenza.consumo /100 ) * 30
        this.pef1 = (this.f1 * 10.4) / 100
        this.pef2 = (this.f2 * 10.4) / 100
        this.pef3 = (this.f3 * 10.4) / 100
        this.kw = (this.f1 + this.f2 + this.f3 )
        this.consumed = (this.f1 + this.f2 + this.f3 + this.pef1 + this.pef2 + this.pef3)        
        this.ivaValueBta = 22
        this.pcvCur = this.currentMonth.pcv
        this.pcvLas = this.lastMonth.pcv
        this.dispbtCur = this.currentMonth.dispbt
        this.dispbtLas = this.lastMonth.dispbt
        this.potenza = this.utenza.potenza
        this.qtfCur = this.currentMonth.trasporto_quota_fissa
        this.qtfLas = this.lastMonth.trasporto_quota_fissa
        this.qofCur = this.currentMonth.oneri_quota_fissa
        this.qofLas = this.lastMonth.oneri_quota_fissa
    }

    //#region Vendita
    pdBimestrale(){
        this.totalePdCur = (this.consumed / 2 ) * this.currentMonth.pd
        this.totalePdLas = (this.consumed / 2 ) * this.lastMonth.pd
        this.totalePdBimestrale = this.totalePdCur + this.totalePdLas
        return this.totalePdBimestrale
    }
    pdMensile(){
        this.totalePdMensile = this.consumed * this.currentMonth.pd
        return this.totalePdMensile
    }
    energiaBimestrale(){
        this.energia_f1 = (this.f1 + this.pef1) / 2
        this.energia_f2 = (this.f2 + this.pef2) / 2
        this.energia_f3 = (this.f3 + this.pef3) / 2
        this.price_cur_f1 = this.energia_f1 *  this.currentMonth.price_tutela.triorario.f1
        this.price_cur_f2 = this.energia_f2 *  this.currentMonth.price_tutela.triorario.f2
        this.price_cur_f3 = this.energia_f3 *  this.currentMonth.price_tutela.triorario.f3
        this.price_las_f1 = this.energia_f1 *  this.lastMonth.price_tutela.triorario.f1
        this.price_las_f2 = this.energia_f2 *  this.lastMonth.price_tutela.triorario.f2
        this.price_las_f3 = this.energia_f3 *  this.lastMonth.price_tutela.triorario.f3
        this.totaleCur = this.price_cur_f1 + this.price_cur_f2 + this.price_cur_f3
        this.totaleLas = this.price_las_f1 + this.price_las_f2 + this.price_las_f3
        return this.totaleCur + this.totaleLas
    }
    energiaMensile(){
        this.energia_f1 = this.f1 + this.pef1
        this.energia_f2 = this.f2 + this.pef2
        this.energia_f3 = this.f3 + this.pef3
        this.price_f1 = this.energia_f1 *  this.currentMonth.price_tutela.triorario.f1
        this.price_f2 = this.energia_f2 *  this.currentMonth.price_tutela.triorario.f2
        this.price_f3 = this.energia_f3 *  this.currentMonth.price_tutela.triorario.f3
        this.totaleEnergiaMensile = this.price_f1 + this.price_f2 + this.price_f3
        return this.totaleEnergiaMensile
    }
    VenditaBimestrale(){
        this.pcvBimestral = this.pcvCur + this.pcvLas
        this.dispbtBimestral = this.dispbtCur + this.dispbtLas
        this.totaleVenditaBimestral = (this.energiaBimestrale() + this.pcvBimestral + this.pdBimestrale() + this.dispbtBimestral)
        return this.totaleVenditaBimestral
    }
    VenditaMensile(){
        this.totaleVenditaMensile = (this.energiaMensile() + this.pcvCur + this.pdMensile() + this.dispbtCur)
        return this.totaleVenditaMensile
        }

    //#endregion

    //#region Trasporto
     quotaEnergiaTrasportoBtaMensile(){
        this.totaleQV = this.kw * this.currentMonth.trasporto_quota_energia
        return this.totaleQV
     }   
     quotaEnergiaTrasportoBtaBimestrale(){
        this.consumeBta = ( this.kw  / 2 )
        this.qvtBtaCur = this.consumeBta * this.currentMonth.trasporto_quota_energia
        this.qvtBtaLas = this.consumeBta * this.lastMonth.trasporto_quota_energia
        return this.qvtBtaCur + this.qvtBtaLas 
     }  

     quotaPotenzaTrasportoBtaMensile(){
         this.totalePotenzaMensile = this.potenza * this.currentMonth.trasporto_quota_potenza
         return this.totalePotenzaMensile
     }  
     quotaPotenzaTrasportoBtaBimestrale(){
         this.potenzaCur = this.potenza * this.currentMonth.trasporto_quota_potenza
         this.potenzaLas = this.potenza * this.lastMonth.trasporto_quota_potenza
         this.totalePotenzaBimestrale = this.potenzaCur + this.potenzaLas
         return this.totalePotenzaBimestrale

     }  

    TrasportoMensile(){
     this.totaleTrasportoMensile = (this.qtfCur + this.quotaPotenzaTrasportoBtaMensile() + this.quotaEnergiaTrasportoBtaMensile())
     return this.totaleTrasportoMensile
    }
    TrasportoBimestrale(){
     this.quoteFisseTrasportoBta = this.qtfCur + this.qtfLas
     this.totaleTrasportoBimestrale = ( this.quoteFisseTrasportoBta + this.quotaPotenzaTrasportoBtaBimestrale() + this.quotaEnergiaTrasportoBtaBimestrale())
    return this.totaleTrasportoBimestrale
    }
    //#endregion

    //#region Oneri

    quotaEnergiaOneriBtaMensile(){
        this.totaleQEO = this.kw * this.currentMonth.oneri_quota_energia
        return this.totaleQEO
     }  
     quotaEnergiaOneriBtaBimestrale(){
        this.consumeBtaO = ( this.kw  / 2 )
        this.qvoBtaCur = this.consumeBtaO * this.currentMonth.oneri_quota_energia
        this.qvoBtaLas = this.consumeBtaO * this.lastMonth.oneri_quota_energia
        return this.qvoBtaCur + this.qvoBtaLas 
     }

     quotaPotenzaOneriBtaMensile(){
        this.potenzaOneriCur = this.potenza * this.currentMonth.oneri_quota_potenza
        return this.potenzaOneriCur
     }
     quotaPotenzaOneriBtaBimestrale(){
         this.oneriPotenzaCur = this.potenza * this.currentMonth.oneri_quota_potenza
         this.oneriPotenzalas = this.potenza * this.lastMonth.oneri_quota_potenza
         this.totaleQuotaPotenzaOneri = this.oneriPotenzaCur + this.oneriPotenzalas
         return this.totaleQuotaPotenzaOneri
     }

     OneriMensile(){
        this.totaleOneriMensile = ( this.qofCur + this.quotaEnergiaOneriBtaMensile() + this.quotaPotenzaOneriBtaMensile())
        return this.totaleOneriMensile
    }
     OneriBimestrale(){
         this.quoteFisseOneriBta = this.qofCur + this.qofLas
         this.totaleOneriBimestrale = ( this.quoteFisseOneriBta + this.quotaEnergiaOneriBtaBimestrale() + this.quotaPotenzaOneriBtaBimestrale())
        return this.totaleOneriBimestrale
     }
    
    //#endregion

    //#region Imposte
     accisaBta(){
         return this.kw * 0.01250
     }
     ivaMensile(){
     this.ivaVenditaMensile = (this.VenditaMensile() * this.ivaValueBta) / 100
     this.ivaTrasportoMensile = (this.TrasportoMensile() * this.ivaValueBta) / 100
     this.ivaOneriMensile = (this.OneriMensile() * this.ivaValueBta) / 100
     this.ivaAccisaBta = (this.accisaBta() * this.ivaValueBta) / 100
     this.totaleIvaMensile = (this.ivaVenditaMensile + this.ivaTrasportoMensile + this.ivaOneriMensile + this.ivaAccisaBta)
     return this.totaleIvaMensile
     }
     ivaBimestrale(){
     this.ivaVenditaBimestrale = (this.VenditaBimestrale() * this.ivaValueBta ) / 100
     this.ivaTrasportoBimestrale = ( this.TrasportoBimestrale() * this.ivaValueBta ) / 100
     this.ivaOneriBimestrale = (this.OneriBimestrale() * this.ivaValueBta ) / 100
     this.ivaAccisaBimestrale = ( this.accisaBta() * this.ivaValueBta ) / 100
     this.totaleIvaBimestrale = (this.ivaVenditaBimestrale + this.ivaTrasportoBimestrale + this.ivaOneriBimestrale + this.ivaAccisaBimestrale)
    return this.totaleIvaBimestrale 
    }

    ImposteMensile(){
        this.totaleImposteMensile = this.accisaBta() + this.ivaMensile()
        return this.totaleImposteMensile
    }
    ImposteBimestrale(){
        this.totaleImposteBimestrale = this.accisaBta() + this.ivaBimestrale()
        return this.totaleImposteBimestrale
    }
    //#endregion
    TotaleBollettaMensile(){
    this.totaleMensileBolletta = (this.VenditaMensile() + this.TrasportoMensile() + this.OneriMensile() + this.ImposteMensile())
    return this.totaleMensileBolletta
    }
    TotaleBollettaBimestrale(){
    this.totaleBimestraleBolletta = (this.VenditaBimestrale() + this.TrasportoBimestrale() + this.OneriBimestrale() + this.ImposteBimestrale())
    return this.totaleBimestraleBolletta
    }
    objBollettaBTA(){
        return {
            bollettaMensile: this.TotaleBollettaMensile(),
            bollettaBimestrale: this.TotaleBollettaBimestrale(),
            venditaMensile: this.VenditaMensile(),
            venditaBimestral: this.VenditaBimestrale(),
            trasportoMensile: this.TrasportoMensile(),
            trasportoBimestrale: this.TrasportoBimestrale(),
            oneriMensile: this.OneriMensile(),
            oneriBimestrale: this.OneriBimestrale(),
            imposteMensile: this.ImposteMensile(),
            imposteBimestrale: this.ImposteBimestrale()
        }
    }
    
}
export default BollettaBta