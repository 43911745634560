import React,{Component} from 'react'
import Snap from 'snapsvg-cjs'

class BoxService extends Component{
constructor(props){
    super(props)
    this.service = this.props.service.bind(this)
    this.statoComponent = this.props.stato.bind(this)
    this.state = {
        statoComponent: false,
        service: ''
    }
    
}
componentDidMount =()=>{
const svg = Snap("#svg")
const home = Snap("#service-home")
const circleHome = home.select("#circle-home")
const sofaBack = home.select("#sofa-back")
const sofaArms = home.select("#sofa-arms")
const business = Snap("#service-business")
const circleBusiness = business.select("#circle-business")
const bagHandle = business.select("#bag-handle")
const paperOne = business.select("#bag-paper-one")
const paperTwo = business.select("#bag-paper-two")

svg.animate({'width': svg.attr('data-width')}, 700, window.mina.bounce)
home.click(() =>{
    if(!this.state.statoComponent){
    clickHome()
    this.service('casa')
    this.statoComponent(true)
    }else{
    this.service('')
    removerClickHome()
    this.statoComponent(false)
    }
})

business.click(()=> {
    if(!this.state.statoComponent){
        clickBusiness()
        this.service('azienda')
        this.statoComponent(true)
        }else{
        this.service('')
        removerClickBusiness()
        this.statoComponent(false)
        }

})

function clickHome(){
circleHome.animate({ 'r': circleHome.attr('data-r')},500,window.mina.linear)
sofaBack.animate({transform: 't0,-10 r12 0'},400, window.mina.bounce , function(){
sofaBack.animate({ transform: 't0,0 r0'},400, window.mina.bounce)    
})
sofaArms.animate({transform: 't0,-10 r12 0'},400, window.mina.bounce,function(){
sofaArms.animate({transform: 't0,0 r0'},700, window.mina.bounce)
business.animate({ transform: 't0,200',},400, window.mina.bounce)
}) 
}
function removerClickHome(){  
circleHome.animate({ 'r': 0},500,window.mina.linear)
sofaBack.animate({transform: 't0,0 r0 0'},400, window.mina.bounce , function(){
sofaBack.animate({ transform: 't0,0 r0'},400, window.mina.bounce)    
})
sofaArms.animate({transform: 't0,0 r0 0'},400, window.mina.bounce,function(){
sofaArms.animate({transform: 't0,0 r0'},700, window.mina.bounce)
business.animate({ transform: 't0,0',},400, window.mina.bounce)
})  
}
 
function clickBusiness(){
circleBusiness.animate({ 'r': circleHome.attr('data-r')},500,window.mina.linear)
bagHandle.animate({ transform: 't0,15'},400, window.mina.bounce, function (){
    paperOne.animate({ transform: 't-2,-15 r10'},400 , window.mina.backout)
    paperTwo.animate({ transform: 't-1,-24 r10'},600 , window.mina.bounce)
    home.animate({ transform: 't0,200',},400, window.mina.bounce)
})
}
function removerClickBusiness(){
    circleBusiness.animate({ 'r': 0 },500,window.mina.linear)
    bagHandle.animate({ transform: 't0,0'},400, window.mina.bounce, function (){
    paperOne.animate({ transform: 't0,0 r0'},400 , window.mina.backout)
    paperTwo.animate({ transform: 't0,0 r0'},600 , window.mina.bounce)
    home.animate({ transform: 't0,0',},400, window.mina.bounce)
    })
}


}//fine componenetdid mount

handlePropsService = () =>{ 

    if(this.state.service === 'casa'){    
        let busi = Snap("#service-business")
        this.service('casa')
        busi.unclick();
    }
    if(this.state.service === 'azienda'){
        let home = Snap("#service-home") 
        this.service('azienda')
        home.unclick();
    }
}

    render(){
        return(
            <div className="panel-caption">
                 <svg id="svg" width="0" data-width="300" height="100" viewBox="0 0 300 100" >
                    <defs>
                    <linearGradient id="one-grad" x1="31%" y1="100%" x2="49%" y2="0%" >
                    <stop offset="0%" stopColor="#00a1a1"/>
                    <stop offset="54%" stopColor="#008C8C"/>
                    <stop offset="100%" stopColor="#007575"/>
                    </linearGradient>  
                    <linearGradient id="two-grad" x1="5%" y1="0%" x2="95%" y2="100%" >
                    <stop offset="0%" stopColor="#1c82ff" stopOpacity=".8"/>
                    <stop offset="54%" stopColor="#3838FC"stopOpacity=".8"/>
                    <stop offset="100%" stopColor="#4108fc"stopOpacity=".7"/>
                    </linearGradient>   
                    </defs>   



                    <g id="service-home" onClick={()=> {!this.state.statoComponent ? this.setState({statoComponent: true , service:'casa'}): this.setState({ statoComponent: false, service:''})}} >
                     <circle id="circle-home" cx="42"cy="45"r="0"fill="#a0cef7" opacity=".5" data-r="37"></circle>  
                     <path 
                     id="sofa-back"
                     fill="white"
                     strokeWidth="4" stroke="#7aa1f5"
                     d="m40 40 h30 c 0 0 4 0 4 4 v16 h-38 v-16 c 0 0 0 -4 4-4z"/>
                     <path 
                     id="sofa-arms"
                     fill="white"
                     strokeWidth="4" stroke="#7aa1f5"
                     d="m35 70 h40 c 0 0 4 0 4 -4 v-15 a 1 1 0 0 0 -8 0 v8  h-34 v-8 a 1 1 0 0 0 -8 0 v15 c 0 0 0 4 4 4 z"/>
                     <text id="home-text" x="20"y="92" fontSize="1em" fill="#30353c">Domestico</text>
                    </g>

                    <g id="service-business" onClick={()=> {!this.state.statoComponent ? this.setState({statoComponent: true , service:'azienda'}): this.setState({ statoComponent: false, service:''})}}>
                    
                    <circle id="circle-business" cx="260"cy="44"r="0" fill="#66FF99" opacity=".5" data-r="28"></circle>
                    <rect id="bag-handle" x="236" y="28" width="14" height="10" fill="white" strokeWidth="2" stroke="#00C9A7" strokeOpacity=".9" strokeLinecap="round" strokeLinejoin="round"></rect>
                    <path id="bag-paper-two"
                    fill="white"
                    strokeWidth="1" stroke="gray" strokeOpacity=".9"   
                    d="m226 40 h36 v30 h-10 v-23 h2 v23 h-10 v-23 h2 v23 h-10 v-23 h2 v23 h-14  v-30z"></path>
                    <path id="bag-paper-one"
                    fill="white"
                    strokeWidth="1"  stroke="gray" strokeOpacity=".9"  
                    d="m226 40 h38 v30 h-10 v-23 h2 v23 h-28  v-30zm15 6 a 1 1 0 0 0 0 5 a -1 -1 0 0 0 0 -4z"></path>

                    <path
                    id="bag-base"
                    fill="white" 
                    strokeWidth="4" stroke="#00C9A7" strokeOpacity=".9"
                    d="m225 70 h36 c 0 0 4 0 4 -4 v -26 c 0 0 0 -4 -4 -4 h-36 c 0 0 -4 0 -4 4 v26 c 0 0 0 4 4 4z"/> 
                    <rect x="222" y="37" width="42" fill="white" height="14" strokeWidth="2" stroke="#00C9A7" strokeOpacity="1" strokeLinejoin="round" strokeLinecap="round"/>
                    <rect x="241" y="48" fill="#cc62cc" width="5" height="5" strokeWidth="4" stroke="#00C9A7" strokeOpacity="1"></rect>
                    <text id="text-business" x="212"y="92" fontSize="1em" fill="#30353c">Business</text>   
                    </g>
            </svg>   
               
        </div>
        )
    }
}
export default BoxService
