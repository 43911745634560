import React from 'react'
import './icons.css'

export const IconRoundInfo = () => {
    return(
       <svg  
       className="Icon RoundInfo"
       fill="#616160"
       viewBox="0 0 24 24">
       <path 
       d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z
       m0 15c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1z
       m1-8h-2V7h2v2z"/>
       </svg> 
    )
}

export const IconRoundInfoRotate = () => {
    return(
        <svg
        className="Icon InfoRound"
        viewBox="0 0 24 24">
        <path 
        d="M11 15h2v2h-2v-2z
        m0-8h2v6h-2V7z
        m.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2z
        M12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
        </svg>
    )
}

export const IconRoundInfoFull = () => {
    return(
        <svg
        className="Icon InfoRoundFull"
        viewBox="0 0 24 24"
        >
            <path 

            fill="white" stroke="#71706e" strokeWidth="1"
            d="m 11.5 2 a 9 9 0 0 0 0 20 a -2 -2 0 0 0 0 -20 z" />
            <path
            viewBox="0 0 24 24"
            fill="#71706e"
            d="m 11.5 5.5 a 1 1 0 0 0 0 4 a -1 -1 0 0 0 0 -4 z" />
            <rect 
            fill="white" stroke="#71706e" strokeWidth="2"
            x="10.5" y="12" width="2" height="5" />    
        </svg>
    )
}