import React, { Component} from 'react'
import Snap from 'snapsvg-cjs'
import './counter.css'


class CounterKw extends Component {
    constructor(props){
        super(props)
        this.handlebuttonPressPlus = this.handlebuttonPressPlus.bind(this)
        this.handlebuttonPressMenus = this.handlebuttonPressMenus.bind(this)
        this.handlebuttonReleasePlus = this.handlebuttonReleasePlus.bind(this)
        this.handlebuttonReleaseMenus = this.handlebuttonReleaseMenus.bind(this)
        this.handleShowCircles = this.handleShowCircles.bind(this)
        this.handleRemoveShow = this.handleRemoveShow.bind(this)
        this.handleClickProps = this.props.click.bind(this)
        this.statusComponent = this.props.stato.bind(this)
        this.state = {
        consumed: this.props.service === 'casa' ? 200 : 400,
        startScreen: 0,
        statoCheck: false
        }
    }
   
    componentDidMount= () => {
    const consumer = Snap("#consumer")
    const circleBack = Snap("#back-consumer")
    const boxConsumer = Snap("#box-consumer")
    const consumerDisplay = consumer.select("#consumer-display")
    boxConsumer.attr({ transform: 't300,0'})
    ini()
    function ini(){
      circleBack.animate({'r': circleBack.attr('data-r')}, 400, window.mina.bounce)  
      consumerDisplay.animate({ 'height': consumerDisplay.attr('data-height')}, 400, window.mina.bounce, function(){
        boxConsumer.animate({ transform: 't0,0'}, 400, window.mina.bounce)
      })
    }
    

    }

    handleShowCircles = () => {
    const circleOne = Snap("#consumer-circle-one")
    const circleTwo = Snap("#consumer-circle-two")
    const circleThree = Snap("#consumer-circle-three")
    const circleFour = Snap("#consumer-circle-four")
    const circleFive = Snap("#consumer-circle-five")

    this.showCircles = setInterval(() => {
        Snap.animate(1,0.1, function(value){
        circleFive.attr({ opacity: value})
        },200, window.mina.linear)   
        Snap.animate(1,0.1, function(value){
        circleFour.attr({ opacity: value})
        },400, window.mina.linear)
        Snap.animate(1,0.1, function(value){
        circleThree.attr({ opacity: value})
        },600, window.mina.linear)  
        Snap.animate(1,0.1, function(value){
        circleTwo.attr({ opacity: value})
        },800, window.mina.linear)
        Snap.animate(1,0, function(value){
        circleOne.attr({ opacity: value })
        },1000, window.mina.linear)
    },1000)
    }

    handleRemoveShow = () => {
        clearInterval(this.showCircles)
    }

    handlebuttonPressPlus = () => {
        this.handleClickPlus()
        this.handleShowCircles()
        if(!this.state.statoCheck){
        this.buttonpressTimerPlus = setInterval(()=>{
        this.setState({ consumed: this.state.consumed === 16660 ? 16660 : this.state.consumed + 1 })   
        },10)   
        }
          
    }
    handlebuttonReleasePlus = () => {
            this.removeClickPlus()
            this.handleRemoveShow()
            clearInterval(this.buttonpressTimerPlus)
    }

    handlebuttonPressMenus = ()=> {
        this.handleClickMenus()
        this.handleShowCircles()
        if(!this.state.statoCheck){
        this.buttonpressTimerMenus = setInterval(()=>{
        this.setState({ consumed: this.state.consumed === 0 ? 0 : this.state.consumed - 1  }) 
        },10)    
        }
          
    }
    handlebuttonReleaseMenus = () => {
        this.removeClickMenus()
        this.handleRemoveShow()
        clearInterval(this.buttonpressTimerMenus)
}
    handleClickPlus = ()=>{
        const switchPlus = Snap("#switch-plus")
        if(!this.state.statoCheck){
        switchPlus.animate({ transform: 's0.9'}, 400, window.mina.bounce)    
        }
        
    }
    removeClickPlus = () =>{
        const switchPlus = Snap("#switch-plus")
        switchPlus.animate({ transform: 's1'}, 400, window.mina.bounce)
    }
    handleClickMenus = ()=>{
        const switchMenus = Snap("#switch-menus")
        if(!this.state.statoCheck){
        switchMenus.animate({ transform: 's0.9'}, 400, window.mina.bounce)    
        }
        
    }
    removeClickMenus = () =>{
        const switchMenus = Snap("#switch-menus")
        switchMenus.animate({ transform: 's1'}, 400, window.mina.bounce)
    }
    handleCheck = () => {
        if(!this.state.statoCheck){
          this.handleClickCheck()
          this.handleClickProps(this.state.consumed) 
          this.statusComponent(true) 
        }else{
          this.removeClickCheck()
          this.statusComponent(false)
        }
    }
    handleClickCheck =()=>{
        const switchCheck = Snap("#switch-check")
        switchCheck.animate({ transform: 's0.95'}, 400, window.mina.bounce)
        this.setState({ statoCheck: true })
    }
    removeClickCheck = ()=>{
        const switchCheck = Snap("#switch-check")
        switchCheck.animate({ transform: 's1'}, 400, window.mina.bounce)
        this.setState({ statoCheck: false })
    }
    stateConsumed = (e) => {
    this.handleClickProps(e)
    }
    render(){
    let counlen = this.state.consumed.toString()
    const isDesktop = /Windows|Macintosh|X11|Ubuntu|Linux/i.test(navigator.userAgent)
    const isMobile = /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent)
    return(
        <div className="progress-counter">
        <svg width="400" height="120" viewBox="0 0 300 120">
                <linearGradient id="bones" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="gray" stopOpacity=".3"  />
                <stop offset="70%" stopColor="gray" stopOpacity=".5" />
                <stop offset="100%" stopColor="gray" stopOpacity=".4" />
                </linearGradient>
                <circle id="back-consumer" cx="42" cy="45" r="0" data-r="37" fill="#ffd700" opacity=".8"/> 
                <g id="consumer" > 
                <rect id="consumer-display" x="20" y="22" width="74" height="0" data-height="53" fill="white" strokeWidth="3" stroke="#666b68" />
                <text x="63" y="35" fill="gray" fontSize="10">KWh</text>
                <text x={
                counlen.length === 1 ? "74" :
                counlen.length === 2 ? "64" : 
                counlen.length === 3 ? "54" : 
                counlen.length === 4 ? "44" :
                "34"
                } y="53" fill="black" fontWeight="bold" fontSize="18">{this.state.consumed}</text>
                <circle id="consumer-circle-one" cx="30" cy="65" r="4" fill="#fff470" opacity=".9"/>
                <circle id="consumer-circle-two" cx="40" cy="65" r="4" fill="#fff470" opacity=".8"/>
                <circle id="consumer-circle-three" cx="50" cy="65" r="4" fill="#fff470" opacity="0"/>
                <circle id="consumer-circle-four"cx="60" cy="65" r="4" fill="#fff470" opacity="0"/>
                <circle id="consumer-circle-five"cx="70" cy="65" r="4" fill="#fff470" opacity="0" />
                </g>
                <g id="box-consumer" >
                    <rect id="rect-consumer" x="126" y="10" width="164" height="80" stroke="gray" strokeWidth="2" fill="white" strokeLinejoin="round"/>
                    {
                        isMobile && 
                        <g id="switch-menus" 
                        onTouchStart={this.handlebuttonPressMenus}
                        onTouchEnd={this.handlebuttonReleaseMenus}>
                        <rect id="box-switch-menus" x="145" y="20" width="40" height="50" stroke="#C6C3C7" strokeWidth="2" fill="white"></rect>
                        <path id="menus-path" stroke="#616161" strokeWidth="2" fill="#616161" strokeLinejoin="round" d="m 159 52 h12 v3 h-12 v-3z" />
                        </g>
                    }
                    {
                        isDesktop &&
                        <g id="switch-menus" 
                        onMouseEnter={this.handlebuttonPressMenus}
                        onMouseOut={this.handlebuttonReleaseMenus}>
                        <rect id="box-switch-menus" x="145" y="20" width="40" height="50" stroke="#C6C3C7" strokeWidth="2" fill="white"></rect>
                        <path id="menus-path" stroke="#616161" strokeWidth="2" fill="#616161" strokeLinejoin="round" d="m 159 52 h12 v3 h-12 v-3z" />
                        </g>
                    }
                    {
                        isMobile &&
                        <g id="switch-plus" 
                        onTouchStart={this.handlebuttonPressPlus}
                        onTouchEnd={this.handlebuttonReleasePlus}>
                        <rect id="box-switch-plus" x="188" y="20" width="40" height="50" stroke="#C6C3C7" strokeWidth="2" fill="white"></rect>
                        <path id="plus-path" fill="#616161"stroke="#616161" strokeWidth="3"  strokeLinejoin="round" d="m206 48 h2 v5 h5v2 h-5v5 h-2 v-5h-5v-2h5v-5z" />
                        </g>
                    }
                    {
                        isDesktop &&
                        <g id="switch-plus" 
                        onMouseEnter={this.handlebuttonPressPlus}
                        onMouseOut={this.handlebuttonReleasePlus}>
                        <rect id="box-switch-plus" x="188" y="20" width="40" height="50" stroke="#C6C3C7" strokeWidth="2" fill="white"></rect>
                        <path id="plus-path" fill="#616161"stroke="#616161" strokeWidth="3"  strokeLinejoin="round" d="m206 48 h2 v5 h5v2 h-5v5 h-2 v-5h-5v-2h5v-5z" />
                        </g>
                    }
                    
                    <g id="switch-check" onClick={this.handleCheck}>
                        <rect  id="box-switch-check"  x="231" y="20" width="40" height="50" stroke="#C6C3C7" strokeWidth="2" fill="white"></rect>
                        <path id="check-path" strokeWidth="2" stroke="#666b68" strokeLinejoin="round" fill="#666b68" 
                        d="m247 50l 3 4 l 5 -5 l2 2 l-7 7l-5 -6l 2 -2z" />
                        <circle cx="250" cy="30" r="4" fill={this.state.statoCheck ? '#22FF50': 'white'} stroke="#666b68"/>

                    </g> 
                    <text id="box-consumer-text" x="187" y="83" fontSize="11" fill="#666b68">premere</text>
                </g>
            </svg>
        </div>
    )
}
}
export default CounterKw
