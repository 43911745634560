import React, { Component} from 'react';
import './App.css';
import Simulatore from '../Simulatore';


class App extends Component {

  render(){
  return (
      <section className="App">
      <div className="App-content">
      <Simulatore />
      </div>
      </section> 
  )
 }
}

export default App;
