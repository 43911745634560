import React, { Component } from 'react'
import './header.css'
import {IconRoundInfoFull }from '../UI//Icons/IconInfo'
import IconNotification from '../UI/Icons/IconNotification';
import  Notifications from '../Notifications' 
import Condition from '../Condition'
import Cookies from 'js-cookie'
import logo from './logo-k-40.png'



class Header extends Component {
    constructor(props){
        super(props)
        this.state = {
        cookieTrue: Cookies.get('Swa'),
        isOpenNotifica: false,
        isOpenInfo: false,
        notifiche: []
      }
    }

componentDidMount = () => {
    if(this.state.cookieTrue){
       fetch('https://kilowatt-app.web.app/notifications', {
       method: 'POST',
       credentials: 'include'
      })
      .then(response => response.json())
      .then(data => {
        this.setState({ notifiche: data })
      })
      .catch(()=> {
        this.setState({ notifiche: [] })
      }) 
    }
    
}
handleOpenNotification=(a)=>{
    this.setState({ isOpenNotifica: a })
}
handleOpenInfo = (a)=>{
    this.setState({ isOpenInfo: a})
}
render(){
 return(
     <div>
        <header className="App-header">
        <span className="header-image">
         <img src={logo} alt="kilowatt energia"/>   
        </span>
        
        <span className="header-title">
        kilowatt
        </span>
        <span className="header-caption">
            {
            this.state.notifiche.length > 0 ? 
            <span onClick={()=> this.setState({ isOpenNotifica: true })}>    
            <IconNotification a={this.state.notifiche.length} color={this.state.notifiche.length > 0 ? true : false}/>  
            </span> : 
            ''
            }
            
            <span onClick={()=>this.setState({ isOpenInfo: true})}  >
            <IconRoundInfoFull />   
            </span>   
        </span>
        </header>
    
        {
           this.state.isOpenNotifica &&
            <Notifications click={this.handleOpenNotification} notifications={this.state.notifiche}/>
        }
        {
            this.state.isOpenInfo &&
            <Condition isOpen={this.handleOpenInfo} />
        }
        </div>
    )}
}

export default Header