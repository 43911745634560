import React from 'react'
import './deviceroute.css';
import celogo from './device.png';

export const Giradispositivo = () => {
    
        return(
            <div className="deviceRoute">
                    <img src={celogo} alt="smartphone around"/> 
                    <h1>KILOWATT</h1>
                    <p>per favore</p>
                    <p>
                      gira il dispositivo  per continuare ..
                    </p>       
            </div>
        )
    
}
