import React,{Component} from 'react';
import Snap from 'snapsvg-cjs'
import './potenza.css'


class BoxPotenza extends Component{
constructor(props){
    super(props)
    this.handlePressPlus = this.handlePressPlus.bind(this)
    this.handlePressMenus = this.handlePressMenus.bind(this)
    this.handleReleasePlus = this.handleReleasePlus.bind(this)
    this.handleReleaseMenus = this.handleReleaseMenus.bind(this)
    this.statusComponent = this.props.stato.bind(this)
    this.hablepp = this.props.click.bind(this)
    this.state = { 
    potenza: 0,
    statoCounter: false
 }
}
componentDidMount = () => {
let CerchioIni = Snap("#cerchio-ini")
let BoxPotenza = Snap("#panelKilo")
let tre = BoxPotenza.select("#tre")
let quattro = BoxPotenza.select("#quattro")
let altro = BoxPotenza.select("#altro")
let blank = BoxPotenza.select("#blank")
let Contatore = Snap("#contatore")
let boxContatore = Contatore.select("#box-contatore")
let boxContatore2 = Contatore.select("#box-contatore-2")
let boxContatoreCerchio = Contatore.select("#box-contatore-cerchio")
let boxContatoreTarga = Contatore.select("#box-contatore-targa")
let boxContatoreElli = Contatore.select("#box-contatore-elli")
const boxCounter = Snap("#box-counter")
boxCounter.attr({transform: 't400,0'})

init()
function init(){
    boxContatore.animate({'height': boxContatore.attr('data-height')},400, window.mina.elastic)
    setTimeout(function(){
    CerchioIni.animate({'r': CerchioIni.attr('data-r')},600,window.mina.elastic)
    boxContatore2.animate({ 'd': boxContatore2.attr('data-d')},400, window.mina.bounce) 
    boxContatoreCerchio.animate({ 'r': boxContatoreCerchio.attr('data-r')},400, window.mina.bounce)
    boxContatoreTarga.animate({ 'width': boxContatoreTarga.attr('data-width')},400, window.mina.linear)
    boxContatoreElli.animate({'rx': boxContatoreElli.attr('data-rx'),opacity:"1"},600, window.mina.backin, viewPotenze)
    },300)
}

function viewPotenze(){
    tre.animate({ transform : 't65, 0' },400, window.mina.linear)
    quattro.animate({ transform: 't130, 0'},400, window.mina.linear)
    altro.animate({ transform: 't0, 0'},400, window.mina.linear, viewAltro)
    blank.attr({ transform: 't0,0'},400, window.mina.easein)
}

function viewAltro() { 
    altro.click(()=>{
    tre.animate({ transform: 't0,0 '},400, window.mina.linear)
    quattro.animate({ transform: 't0,0'},400, window.mina.linear)
    blank.animate({ transform: 't53,0'},400,window.mina.linear, viewCounter)
    })
}

function viewCounter() {
    boxCounter.animate({ transform: 't0,0'}, 400 ,window.mina.bounce)
}



}//fine del comoponne did mount

handlePressPlus = () => {
    this.handleClickPlus()
    if(!this.state.statoCounter){
    this.pressTimerPlus = setInterval(()=>{
    this.setState({ potenza: this.state.potenza === 99 ? 99 : this.state.potenza + 0.5 })
    },300)   
    }   
}
handleReleasePlus = () => {
    this.removeClickPlus()
    clearInterval(this.pressTimerPlus)
}
handlePressMenus = ()=> {
    this.handleClickMenus()
    if(!this.state.statoCounter){
    this.pressTimerMenus = setInterval(()=>{
    this.setState({ potenza: this.state.potenza === 0 ? 0 : this.state.potenza - 0.5 })  
    },300)    
    } 
}
handleReleaseMenus = () => {
    this.removeClickMenus()
    clearInterval(this.pressTimerMenus)
}

handleClickMenus = ()=>{
    const switchMenus = Snap("#counter-switch-menus")
    if(!this.state.statoCounter){
    switchMenus.animate({ transform: 's0.9'}, 400, window.mina.bounce)    
    }
    
}
removeClickMenus = () =>{
    const switchMenus = Snap("#counter-switch-menus")
    switchMenus.animate({ transform: 's1'}, 400, window.mina.bounce)
}
handleClickPlus = ()=>{
    const switchPlus = Snap("#counter-switch-plus")
    if(!this.state.statoCounter){
    switchPlus.animate({ transform: 's0.9'}, 400, window.mina.bounce)    
    }
    
}
removeClickPlus = () =>{
    const switchPlus = Snap("#counter-switch-plus")
    switchPlus.animate({ transform: 's1'}, 400, window.mina.bounce)
}

handleComponentTre = (e) => {
this.setState({ potenza: e ,statoCounter: true})
let kilos = Snap("#panelKilo")
let quattro = kilos.select("#quattro")
quattro.animate({transform: 't0,0'},400, window.mina.bounce)
this.handlePotenze(e)
this.statusComponent(true)
}
handleComponentQuattro = (e) => {
this.setState({ potenza: e, statoCounter: true})
let kilos = Snap("#panelKilo")
let tres = kilos.select("#tre")
tres.animate({transform: 't0,0'},400, window.mina.bounce)
this.handlePotenze(e)
this.statusComponent(true)
}
handlePotenze = (e) => {
this.hablepp(e)
}
handleClickOthers =()=>{
    const switchOthers = Snap("#counter-switch-check")
    switchOthers.animate({ transform: 's0.95'}, 400, window.mina.bounce)
    this.setState({ statoCounter: true })
}
removeClickOthers =()=>{
    const switchOthers = Snap("#counter-switch-check")
    switchOthers.animate({ transform: 's1'}, 400, window.mina.bounce)
    this.setState({ statoCounter: false })
}
handleOthers = () =>{
    if(!this.state.statoCounter){
        this.handleClickOthers()
        this.handlePotenze(this.state.potenza)
        this.statusComponent(true)
    }else{
        this.removeClickOthers()
        this.statusComponent(false)
    }
} 
render(){
        const { service  } = this.props
        const strinState = this.state.potenza.toString();
        const isDesktop = /Windows|Macintosh|X11|Ubuntu|Linux/i.test(navigator.userAgent)
        const isMobile = /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent)
        return(
            <div className="progress-counter">
            <svg id="svg" width="400" height="120" viewBox="0 0 340 120">
                <g id="groupSvgFirst">
                <circle id="cerchio-ini" cx="60" cy="53" r="0" fill="#ffd700" opacity=".8" data-r="37" ></circle>
                
                <g id="panelKilo">
                    <g id="tre" onClick={()=>this.handleComponentTre(3)} >
                    <rect x="40" y="40" width="42" height="45" fill="white" stroke="#cacaca"  strokeWidth="3"></rect>
                    <text x="50" y="55" fontSize="13" fontWeight="bold" fill="#666b68">KW</text>
                    <text x="56" y="75" fontSize="20" fontWeight="bold" letterSpacing="-1" fill="#666b68">3</text>
                    </g>
                    <g id="quattro" onClick={()=>this.handleComponentQuattro(service === 'casa'? 4.5 : 6)}>
                    <rect x="40" y="40" width="42" height="45" fill="white" stroke="#cacaca" strokeWidth="3" ></rect>
                    <text x="52" y="55" fontSize="13" fontWeight="bold" fill="#666b68" >KW</text>
                    <text x={service === 'casa' ? "48" : "56"} y="75" fontSize="20" fontWeight="bold" fill="#666b68" letterSpacing="-1">{service === 'casa' ? 4.5: 6}</text>
                    </g>
                    
                    <g id="altro" >
                    <path d="m 250 45 l -10 10 l 10 10l 2 -2 l -8 -8 l8 -8 l -2 -2z"  fill="#71706e" stroke="#71706e" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> 
                    <circle cx="260" cy="55"r="4" fill="#a6a6a1"/>
                    <circle cx="270" cy="55"r="4" fill="#a6a6a1"/>
                    <circle cx="280" cy="55"r="4" fill="#a6a6a1"/>
                    <path d="m 290 45 l 10 10 l -10 10l -2 -2 l 8 -8 l-8 -8 l 2 -2z"fill="#71706e"  stroke="#71706e" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> 
                    <text x="256" y="79" fontWeight="bold" fontSize="12px" fill="gray">Altre</text>
                    <rect id="legend" x="240" y="25" width="80" height="60" fill="transparent" onClick={() => !this.state.settingsDisplay ? this.setState({ settingsDisplay: true}): this.setState({ settingsDisplay: false})} /> 
                    </g>

                    <g id="blank">
                    <rect x="40" y="40" width="42" height="45" fill="white" stroke="#77838c" strokeWidth="3"></rect>
                    <text x="50" y="55" fontSize="14" fontWeight="bold" fill="#666b68">KW</text>
                    <text x={strinState.length === 1 ? "56" : strinState.length === 2 ? "51" : strinState.length === 3 ? "49": "43" } y="75" fontSize="20" fontWeight="bold" letterSpacing={strinState.length > 2 ? -1 : -3} fill="#666b68">{this.state.potenza}</text>
                    </g>
                </g>

                <g id="contatore" x="400"> 
                     <rect id="box-contatore" x="40"y="34" width="45" height="0" fill="white" rx="5" ry="5" stroke="#666b68" strokeWidth="3" data-height="57"></rect>   
                     <path id="box-contatore-2" fill="white"stroke="#77838c" opacity=".4" strokeWidth="2.5" d="" data-d="m 43 39 h 36 v 15 l -3 7 l 3 7 a 1 1 7 0 1 -36 0  l 3 -7 l -3 -7 v -15h1z" />                   
                     <circle id="box-contatore-cerchio" cx="73" cy="46" r="0" fill="white" stroke="#666b68" strokeWidth="3px" data-r="3"></circle>
                     <rect id="box-contatore-targa" x="48" y="43" width="0" height="10" stroke="#666b68" strokeWidth="3px" fill="white" data-width="15" />
                     <ellipse id="box-contatore-elli" cx="61" cy="75" rx="1" ry="4" data-rx="8" fill="#666b68" opacity="0"/>
                </g>
                </g>

                <g id="box-counter" >
                    <rect id="rect-counter" x="145" y="20" width="164" height="80" stroke="gray" strokeWidth="2" fill="white" strokeLinejoin="round"/>
                    { isDesktop && 
                    <g id="counter-switch-menus"      
                    onMouseEnter={this.handlePressMenus}
                    onMouseOut={this.handleReleaseMenus}>
                         <rect id="box-switch-menus" x="164" y="30" width="40" height="50" stroke="#C6C3C7" strokeWidth="2" fill="white"></rect>
                         <path id="menus-path" stroke="#616161" strokeWidth="2" fill="#616161" strokeLinejoin="round" d="m 179 63 h12 v3 h-12 v-3z" />
                     </g>
                    }
                    { isMobile &&
                    <g id="counter-switch-menus"      
                    onTouchStart={this.handlePressMenus}
                    onTouchEnd={this.handleReleaseMenus}>
                         <rect id="box-switch-menus" x="164" y="30" width="40" height="50" stroke="#C6C3C7" strokeWidth="2" fill="white"></rect>
                         <path id="menus-path" stroke="#616161" strokeWidth="2" fill="#616161" strokeLinejoin="round" d="m 179 63 h12 v3 h-12 v-3z" />
                     </g>
                    }
                    {
                        isDesktop &&
                    <g id="counter-switch-plus" 
                    onMouseEnter={this.handlePressPlus}
                    onMouseOut={this.handleReleasePlus}>
                        <rect id="box-switch-plus" x="207" y="30" width="40" height="50" stroke="#C6C3C7" strokeWidth="2" fill="white"></rect>
                        <path id="plus-path" fill="#616161"stroke="#616161" strokeWidth="3"  strokeLinejoin="round" d="m225 58 h2 v5 h5v2 h-5v5 h-2 v-5h-5v-2h5v-5z" />
                    </g>
                    }
                    {
                        isMobile &&
                    <g id="counter-switch-plus" 
                    onTouchStart={this.handlePressPlus}
                    onTouchEnd={this.handleReleasePlus}>
                        <rect id="box-switch-plus" x="207" y="30" width="40" height="50" stroke="#C6C3C7" strokeWidth="2" fill="white"></rect>
                        <path id="plus-path" fill="#616161"stroke="#616161" strokeWidth="3"  strokeLinejoin="round" d="m225 58 h2 v5 h5v2 h-5v5 h-2 v-5h-5v-2h5v-5z" />
                    </g>
                    }
                    
                    <g id="counter-switch-check" onClick={this.handleOthers}>
                        <rect  id="box-switch-check"  x="250" y="30" width="40" height="50" stroke="#C6C3C7" strokeWidth="2" fill="white"></rect>
                        <path id="check-path" strokeWidth="2" stroke="#666b68" strokeLinejoin="round" fill="#666b68" 
                        d="m266 60l 3 4 l 5 -5 l2 2 l-7 7l-5 -6l 2 -2z" />
                        <circle cx="269" cy="40" r="4" fill={this.state.statoCounter ? '#22FF50': 'white'} stroke="#666b68"/>

                    </g> 
                    <text id="box-consumer-text" x="206" y="93" fontSize="11" fill="#666b68">premere</text>
                </g>
                
            </svg>       
            </div>
        )
    }
}

export default BoxPotenza
