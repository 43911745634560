import React, { Component } from 'react'
import UtenzaSimulatore from './UtenzaSimulatore'
import './bid.css'
import Raiting from '../UI/Raiting';
import ButtonLike from '../UI/FB/ButtonLike'
import HomeOptions from '../UI/HomeOptions';
import OptionsBusiness from '../UI/BusinessOptions';
import BollettaTd from '../Bolletta/BollettaTd';
import BollettaBta from '../Bolletta/BollettaBta';
import BollettaBta6 from '../Bolletta/BollettaBta6';
import BoxRender from './boxRender';
import Votes from '../UI/Votes';


class Simulatore extends Component {
    
    state = {
        completo: false,
        statoRete: false,
        checked : false,
        stateResponse: false,
        stateImpegnata: false,
        risposta: '',
        stateVote: false,
        fillTv: false,
        fillNolocation: false,
        potenzaImpegnata: 0,
        bolletton: 0,
        statusImpComponent: false,
        statusComparation: false,
        statusVote: false,
        statusOptions: false,
        statusGo: false,
        Comparation: 0
    }
    

    handleOptions = (e) => {
        this.setState({ tab_cotent: e.target.id})
    }
    hanldeCompleted = (a) =>{
        this.setState({ completo: true, utenza: a})
        if(a.service === 'casa'){
        this.fetchHome()
        }
        if(a.service === 'azienda')
         a.tariffa !== 'bta6' ? this.fetchBta(a) : this.fetchBta6(a) 
    }
    fetchHome = () => {
        let myheaders = new Headers()        
        myheaders.append('Content-type','application/json')
        fetch('https://rete-api.web.app/energia/td/?apiKey=ozvqHmBBncVB2jk7Cf3hfMHuXKy1')
        .then(response => response.json())
        .then(data => {
            let bollettaTD = new BollettaTd(this.state.utenza, data)
            this.setState({ totale: bollettaTD.objBolletta() , rete: data})
            this.setState({ statoRete: true , bolletton: this.state.totale.bolletta}) 
        })
        .catch(err => {
            console.log(err)
        })
    

    }
    fetchBta = (a) => {
        let myheaders = new Headers()        
        myheaders.append('Content-Type','application/json')
        fetch(`https://rete-api.web.app/energia/${a.tariffa}/?apiKey=ozvqHmBBncVB2jk7Cf3hfMHuXKy1`, myheaders)
        .then(response => response.json())
        .then(data=> {
            let bollettaBta = new BollettaBta(this.state.utenza, data)
            this.setState({ totale: bollettaBta.objBollettaBTA()})
            this.setState({ statoRete: true , bolletton: this.state.totale.bollettaMensile}) 
        })
        .catch(err => {
            console.log(err)
        })

    }
    fetchBta6=(a)=> {
        const date = new Date().getMonth()-2
        const mm = ['M01','M02','M03','M04','M05','M06','M07','M08','M09',]
        fetch(`https://rete-api.web.app/energia/${a.tariffa}/${mm[date]}/?apiKey=ozvqHmBBncVB2jk7Cf3hfMHuXKy1`)
        .then(response => response.json())
        .then(data => {
            let bollettaMese = new BollettaBta6(this.state.utenza, data)
            this.setState({ totale: bollettaMese.objBollettaMese()})
            this.setState({ statoRete: true, bolletton: this.state.totale.bollettaMese })
        })
        .catch(err => 
            console.log(err))

    }
    statoComponentImp = (a) =>{
        this.setState({ statusImpComponent: a })    
    }
    handlePotenzaImpegnata = (a) => {
        this.setState({ potenzaImpegnata: a })
        let trasportoPotenza = this.state.totale.potenzaTrasporto * a
        let oneriPotenza = this.state.totale.potenzaOneri * a
        let ivaTrasporto = (trasportoPotenza * 22 ) / 100
        let ivaOneri = (oneriPotenza * 22 ) / 100
        let bollettaSenzaPotenza = this.state.totale.bollettaImp
        const newBolletta = ( bollettaSenzaPotenza + trasportoPotenza + oneriPotenza + ivaOneri + ivaTrasporto)
        if(this.state.statusImpComponent){
           this.setState({ bolletton: this.state.totale.bollettaMese , statusOptions: false, Comparation: this.state.totale.bollettaMese})     
        }else{
           this.setState({ bolletton: newBolletta , statusOptions: true, Comparation: newBolletta})    
        }
           
    }
    handleComparation = (a) => {
        this.setState({ statusComparation: a })  
    }
    handleEndApp = (a) =>{
    this.setState({ statusGo: true, vote: a, stateResponse: true })
    fetch('https://kilowatt-app.web.app/service', { 
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                use: this.state.utenza.service,
                consumed: this.state.utenza.consumo,
                power: this.state.utenza.potenza,
                powerImp: this.state.potenzaImpegnata || 0,
                rate: this.state.utenza.tariffa,
                bill: this.state.bolletton,
                total: this.state.totale,
                vote: a,
                compare: this.state.compare
            })
        })
        .then(() =>{
                this.setState({ statusVote: true })
            }
        ) 
    }
    handleCalendar = (e) => {
        this.setState({ calendar: e })
    }
    handleStateComparation = (e) => {
        this.setState({ compare : e})
    }
    handleBimestrale = (e) => {
        this.setState({ calendarBimestrale: e })
        if(!this.state.statusOptions){
        this.setState({ Comparation: this.state.totale.bollettaBimestrale, statusOptions: true})
        this.setState({ bolletton: this.state.totale.bollettaBimestrale})
        }else{
        this.setState({Comparation: 0 , statusOptions: false})
        }
    }

    handleMensile = (e) => {
        this.setState({ calendarMensile: e })
        if(!this.state.statusOptions){
            this.setState({ Comparation: this.state.totale.bollettaMensile, statusOptions: true})
            this.setState({ bolletton: this.state.totale.bollettaMensile })
        }else{
            this.setState({ Comparation: 0, statusOptions: false})
        }
    }
    
    handleResident = (a) => {
        if(a === 'trueResidente'){  
        this.setState({ bolletton: this.state.totale.bolletta + 18.00, statusOptions: true, Comparation: this.state.totale.bolletta + 18.00})
        }
        if(a === 'trueNonResidente'){
        this.setState({ bolletton:  this.state.totale.bollettaNr, statusOptions: true, Comparation: this.state.totale.bollettaNr})
        }
        if(a === 'falseResidente'){
        this.setState({ bolletton: this.state.totale.bolletta , statusOptions: false, Comparation: this.state.totale.bolletta})
        }
        if(a === 'falseNonResidente'){
        this.setState({ bolletton: this.state.totale.bolletta, statusOptions: false, Comparation: this.state.totale.bolletta })
        }

    }
    render(){  
        if(this.state.statoRete){
            return(
            <div className="box-bid">
                <div className="bid-content">    
                    <div className="bolletta-render">  
                        <BoxRender  />
                        <div className="bolletta-price">            
                        <span id="totale">€ { this.state.bolletton.toFixed(2).replace('.',',') }</span>
                        <p className="p-totale">€/Totale Bolletta *</p>
                        </div>
                    </div>                    
                    <div className="info-bolletta">* Il totale include tutte le quote e imposte </div>

                </div>
                    { 
                    
                    this.state.utenza.service === 'casa' ?
                    <div className="options-service">
                        <h4>Scegli una opzione</h4>
                      <HomeOptions click={this.handleResident} quo={this.state.totale.quotaFissaNonResidente} />
                    </div> :
                    <div className="options-service">
                      <OptionsBusiness 
                      power={this.state.utenza.potenza} 
                      periodo={this.handleCalendar}
                      mensile={this.handleMensile}
                      bimestrale={this.handleBimestrale}
                      imp={this.handlePotenzaImpegnata}
                      stato={this.statoComponentImp}/>
                    </div>
                    }
                    
                    <div className="tab-container"> 
                    {
                    this.state.statusOptions  &&
                    <Raiting click={this.handleComparation} bolletta={this.state.Comparation} rates={this.handleStateComparation}/>   
                    }
                    {
                    this.state.statusComparation  &&
                    <Votes stato={this.state.statusComparation} endy={this.handleEndApp}/>
                    }
                    </div>
                    {        
                    this.state.statusGo &&
                    <div className="div-absolute-100">
                    <ButtonLike />
                    </div>
                    }  
                     
                          
               </div>
            )
        }else{
            return( 
            <UtenzaSimulatore click={this.hanldeCompleted} />
            )
        }
        
        
    }
}


export default Simulatore
