import React, {Component} from 'react'
import './cover.css'
import PrivacyCookie from '../../PrivacyCookie';

class Cover extends Component {
    constructor(props){
        super(props)
        this.getcool = this.props.cov
        this.state = {
        cover: this.getcool ? true : false,
        openPrivacy: false
}
    }

componentDidMount = () => {
    if(this.getcool){
        this.setState({ cover: true , divName: true })     
    }
}
handleClosePrivacy = (a)=> {
    this.setState({ openPrivacy: a})
}

    render(){
    const { cov } = this.props
    if(cov){
       return(
           
        <div className="div-policy">
                <button className="visit-privacy" onClick={()=> this.setState({ openPrivacy: true})}>
                 <h5>scopri di più su Kilowatt e privacy</h5>
                </button>
             {
                 this.state.openPrivacy &&
                 <PrivacyCookie  isclose={this.handleClosePrivacy}/>
             }
        </div>)
    }else{
        return(
            
        <div className="pps">
        {
                 this.state.openPrivacy 
                 ? <PrivacyCookie  isclose={this.handleClosePrivacy}/>
                 : <div className="Banner-content">
        Questo sito web utilizza cookie tecnici per fornire alcuni servizi. Continuando la navigazione,
        acconsenti al loro utilizzo in conformità alla nostra Informativa sulla 
        <strong onClick={() => this.setState({ openPrivacy: true })}> privacy e cookie policy</strong>
        </div>
        }
    
        
             
        </div> )
    }
             
  }
}
export default Cover
