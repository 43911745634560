import React, { Component } from 'react'
import CounterKw from '../UI/CounterKw';
import BoxPotenza from '../UI/BoxPotenza';
import IconSpinner from '../UI/Icons/IconSpinner';
import BoxService from '../UI/BoxService';
import ImagePage from '../Main/ImagePage';
import BtnCalcola from '../UI/BtnCalcola';



class UtenzaSimulatore extends Component {
  constructor(props){
    super(props)
    this.handleGo = this.props.click.bind(this)
    }
    state = {
        service: '',
        consumo: 0,
        tariffa: '',
        potenza: 0,
        statoService: false,
        statoConsumo: false,
        statoPotenza: false ,
        completed: false,
        altro: false,
        ini: false,
        intros: false
      }
    handleGos = (a) => {
      this.handleGo(this.state)
      this.setState({ intros: true})
    }
    statusComponentService = (e) => {
      if(e === true){ this.setState({ statoService: true })}
      if(e === false){ this.setState({ statoService: false , statoConsumo: false, statoPotenza: false, completed: false })}
    }
    handleService = (e) =>{
      if(e === 'casa'){
        this.setState({ service: 'casa',  tariffa: 'td'})
      }
      if(e ==='azienda'){
        this.setState({ service: 'azienda',  tariffa: ''})
      }
    }
  
    handleCounter = (a) => {
      this.setState({ consumo: a })
    }
    statusComponentPotenza = (a) =>{
       this.setState({ statoPotenza: a})
    }
   
    handleComponentPotenza = (a) => {
      if(this.state.service === 'azienda'){
        if(a <= 1.5){ this.setState({ tariffa: 'bta1'})}
        if(a > 1.5 && a <= 3){ this.setState({ tariffa: 'bta2'})}
        if(a > 3 && a <= 6){ this.setState({ tariffa: 'bta3'})}
        if(a > 6 && a <= 10){ this.setState({ tariffa: 'bta4'})}
        if(a > 10 && a <= 16.5){ this.setState({ tariffa: 'bta5'})}
        if(a > 16.5 && a <= 100){ this.setState({ tariffa: 'bta6'})}
      }
      this.setState({ potenza: a })
    }
    statusComponentConsumed = (a) => {
     this.setState({statoConsumo: a, completed: a})
    }
    render(){
        return(  
            <div className="simulatore">            
            <div className="panel-simulatore-service">
            <div className="panel-title"> <h4>sei un cliente</h4> </div>
            <BoxService service={this.handleService} stato={this.statusComponentService}/>
          </div>
          {
            this.state.statoService &&
           <div className="panel-simulatore-potenza">
            <div className="panel-title"><h4>la Potenza del tuo contatore</h4></div>
            <BoxPotenza service={this.state.service} click={this.handleComponentPotenza} stato={this.statusComponentPotenza}/>
            </div>      
          }
          {
            this.state.statoPotenza &&
            <div className="panel-simulatore-consumo">
             <div className="panel-title"><h4>Imposta il tuo consumo</h4></div>
            <CounterKw service={this.state.service} click={this.handleCounter} stato={this.statusComponentConsumed}/>
            </div> 
            }
            {

            this.state.completed &&
            <div className="panel-calcola">   
            <BtnCalcola clico={this.handleGos} />
            </div>
      
            }
            {
             !this.state.statoService &&
             <ImagePage /> 
            }
            {
              this.state.intros &&
              <div className="div-absolute-100 intos">
                <span className="carico">
                  <IconSpinner />
                </span>
              </div>
            }
        </div>
        )
    }
}

export default UtenzaSimulatore