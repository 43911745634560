import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './Media/post-102019.png'
import './Media/post-fb-4.png'
import * as serviceWorker from './serviceWorker';
import Main from './Component/Main';
import DeviceOrientation, {Orientation} from './Component/Creen'
import { Giradispositivo } from './Component/Main/GiraDevice';


document.addEventListener('touchmove', (e) => {
    if(e.scale !== 1){
        e.preventDefault();
    }
}, false )

var lastTouchEnd = 0;
document.addEventListener('touchend', (event) =>{
  var now = (new Date()).getTime();
  if (now - lastTouchEnd <= 300) {
    event.preventDefault();
  }
  lastTouchEnd = now;
}, false);

document.addEventListener('gesturechange', (event)=>{
  if(event.type !== 'click' || 'touchmove'|| 'touchend' || 'touchstart' ){
  event.preventDefault();
  }
}, false)



ReactDOM.render(
  <DeviceOrientation lockOrientation={'landscape'}>
    
    <Orientation orientation='landscape' alwaysRender={false}>
     <Giradispositivo/>
    </Orientation>
     
    <Orientation orientation='portrait' >
<Main/>
    </Orientation>

  </DeviceOrientation>
     
, document.getElementById('root'));
serviceWorker.unregister();
