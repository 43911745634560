import React,{Component} from 'react'

class Votes extends Component{
    constructor(props){
        super(props)
        this.state= {
            statoComponentVote: this.props.stato
        }
    }
    render(){
        const { endy } = this.props
        return(
            this.state.statoComponentVote &&
            <div>
                <svg width="300" height="100" viewBox="0 0 300 100" >
                    <rect x="100" y="30" width="100" height="40" fill="#7aa1f5"  />
                    <text x="120" y="55" fontSize="20" fontWeight="bold" fill="white">Fatto !</text>
                    <rect x="70" y="10" width="160" height="80" fill="transparent" onClick={endy.bind(this, this.state)}/>
                </svg>
            </div>
        )
    }
}
export default Votes