class BollettaBta6 {
    constructor(utenza, rete){
        this.utenza = utenza
        this.reteMese = rete
        this.mf1 = (this.utenza.consumo /100 ) * 45
        this.mf2 = (this.utenza.consumo /100 ) * 25
        this.mf3 = (this.utenza.consumo /100 ) * 30
        this.pemf1 = (this.mf1 * 10.4) / 100
        this.pemf2 = (this.mf2 * 10.4) / 100
        this.pemf3 = (this.mf3 * 10.4) / 100
        this.kwMese = (this.mf1 + this.mf2 + this.mf3 )
        this.consumedMese = (this.kwMese + this.pemf1 + this.pemf2 + this.pemf3)
        this.pcvMese = this.reteMese.pcv
        this.disptMese = this.reteMese.dispbt
        this.qtfMese = this.reteMese.trasporto_quota_fissa
        this.qofMese = this.reteMese.oneri_quota_fissa
        this.potenzaMese = this.utenza.potenza
        this.ivaBtaMese = 22
    }
    //#region Vendita
    pdMese(){
        this.totalePd = (this.kwMese * this.reteMese.pd)
        return this.totalePd
    }
    energiaMese(){
        this.price_mese_f1 = (this.mf1 + this.pemf1) * this.reteMese.price_tutela.triorario.f1
        this.price_mese_f2 = (this.mf2 + this.pemf2) * this.reteMese.price_tutela.triorario.f2
        this.price_mese_f3 = (this.mf3 + this.pemf3) * this.reteMese.price_tutela.triorario.f3
        this.totaleEnergiaMese = ( this.price_mese_f1 + this.price_mese_f2 + this.price_mese_f3)
        return this.totaleEnergiaMese
    }
    VenditaMese(){
        this.totaleVenditaMese = (this.pcvMese + this.pdMese() + this.disptMese + this.energiaMese())
        return this.totaleVenditaMese
    }
    //#endregion

    //#region Trasporto
    quotaEnergiaTrasportoMese(){
        this.qetMese = (this.kwMese * this.reteMese.trasporto_quota_energia)
        return this.qetMese
    }
    quotaPotenzaTrasportoMese(){
        this.qptMese = (this.potenzaMese * this.reteMese.trasporto_quota_potenza)
        return this.qptMese
    }
    TrasportoMese(){
        this.totaleTrasportoMese = ( this.qtfMese + this.quotaEnergiaTrasportoMese() + this.quotaPotenzaTrasportoMese())
        return this.totaleTrasportoMese
    }
    TrasportoImp(){
        this.totaleTrasportoImp = ( this.qtfMese + this.quotaEnergiaTrasportoMese())
        return this.totaleTrasportoImp
    }
    //#endregion

    //#region Oneri

    quotaEnergiaOneriMese(){
        this.qeoMese = (this.kwMese * this.reteMese.oneri_quota_energia)
        return this.qeoMese
    }
    quotaPotenzaOneriMese(){
        this.qopMese = (this.potenzaMese * this.reteMese.oneri_quota_potenza)
        return this.qopMese
    }
    OneriMese(){
        this.totaleOneriMese = (this.qofMese + this.quotaEnergiaOneriMese() + this.quotaPotenzaOneriMese())
        return this.totaleOneriMese
    }

    OneriImp(){
        this.totaleOneriImp = ( this.qofMese + this.quotaEnergiaOneriMese() )
        return this.totaleOneriImp
    }
    //#endregion
    //#region Imposte

    accisaMese(){
        this.totaleAccisaMese = ( this.kwMese * 0.01250 )
        return this.totaleAccisaMese
    }
    ivaMese(){
        this.ivaVenditaMese = (this.VenditaMese() * this.ivaBtaMese)/100
        this.ivaTrasportoMese = (this.TrasportoMese() * this.ivaBtaMese)/100
        this.ivaOneriMese = ( this.OneriMese() * this.ivaBtaMese)/100
        this.ivaAccisaMese = ( this.accisaMese() * this.ivaBtaMese) / 100
        this.totaleIvaMese = ( this.ivaVenditaMese + this.ivaTrasportoMese + this.ivaOneriMese + this.ivaAccisaMese)
        return this.totaleIvaMese
    }
    ivaNot(){
        this.ivaVenditaImp = (this.VenditaMese() * this.ivaBtaMese) / 100
        this.ivaTrasportoImp = (this.TrasportoImp() * this.ivaBtaMese) / 100
        this.ivaOneriImp = ( this.OneriImp() * this.ivaBtaMese) / 100
        this.ivaAccisaImp = ( this.accisaMese() * this.ivaBtaMese) / 100
        this.totaleIvaImp = ( this.ivaVenditaImp + this.ivaTrasportoImp + this.ivaOneriImp + this.ivaAccisaImp)
        return this.totaleIvaImp
    }
    ImposteMese(){
        this.totaleImposteMese = ( this.accisaMese() + this.ivaMese())
        return this.totaleImposteMese
    }
    ImposteImp(){
        this.totaleImposteImp = (this.accisaMese() + this.ivaNot())
        return this.totaleImposteImp
    }
    //#endregion
    TotaleMese(){
        this.totaleMese = (this.VenditaMese() + this.TrasportoMese() + this.OneriMese() + this.ImposteMese())
        return this.totaleMese
    }
    TotaleImp(){
        this.totaleImp = (this.VenditaMese() + this.TrasportoImp() + this.OneriImp() + this.ImposteImp())
        return this.totaleImp
    }

    objBollettaMese(){
        return {
            bollettaMese: this.TotaleMese(),
            bollettaImp: this.TotaleImp(),
            venditaMese: this.VenditaMese(),
            trasportoMese: this.TrasportoMese(),
            oneri: this.OneriMese(),
            imposte: this.ImposteMese(),
            potenzaTrasporto: this.reteMese.trasporto_quota_potenza,
            potenzaOneri: this.reteMese.oneri_quota_potenza
        }
    }
}
export default BollettaBta6