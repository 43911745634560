import React,{Component} from 'react'
import {IconCloseRound} from '../UI/Icons/IconClose'
import Notifica from './notifica'

class Notifications extends Component{
   constructor(props){
     super(props)
     this.closeModule = this.props.click.bind(this, false)
     this.state ={
       notifiche: this.props.notifications
      }
   }
  
    render(){

        return(
        <div className="div-absolute-100">
         <header className="App-header">
        <span className="header-title">Notifiche
          </span>
          <span className="header-caption" >
            <span onClick={this.closeModule}>
            <IconCloseRound />
            </span>
          </span>
         </header>
         <div className="notification-div H90">
         <div className="notification-list">
                <div className="div-center-column">
                  {
                    this.state.notifiche.map(notifica => {
                      return(
                        <Notifica key={notifica} not={notifica} />
                      )
                    })
                  }
                </div>
         </div>
         </div>
              
        </div>
        )
    }
} 
export default Notifications