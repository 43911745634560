import React, {Component} from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import { Switch , Route} from 'react-router-dom'
import Header from '../Header'
import App from '../App'
import Footer from '../Footer';



class Main extends Component{

    render(){
        return(
            <Router >
                <Header  />          
                <Switch> 
                    <Route exact path="/" component={App} />
                </Switch> 
                 <Footer />             
            </Router>
            
        )
    }
}
export default Main