class BollettaTd {
    constructor(utenza, rete){
        this.utenza = utenza
        this.currentMonth = rete.currentMonth
        this.lastMonth = rete.lastMonth
        this.f1 = (this.utenza.consumo /100 ) * 41
        this.f2 = (this.utenza.consumo /100 ) * 22
        this.f3 = (this.utenza.consumo /100 ) * 37
        this.pef1 = (this.f1 * 10.4) / 100
        this.pef2 = (this.f2 * 10.4) / 100
        this.pef3 = (this.f3 * 10.4) / 100
        this.consumed = (this.f1 + this.f2 + this.f3 + this.pef1 + this.pef2 + this.pef3)        
        this.ivaValue = 10
    }
    //#region  settings Consumed
    //#endregion

//#region Vendita
pcv(){
    this.pcvCur = this.currentMonth.pcv
    this.pcvLas = this.lastMonth.pcv
    return this.pcvCur + this.pcvLas
}    
pd(){
    this.pdCur = this.currentMonth.pd  * ( this.consumed / 2 )
    this.pdLas = this.lastMonth.pd * ( this.consumed / 2)
    return this.pdCur + this.pdLas
}
dispbt(){
    this.dispbtCur = this.currentMonth.dispbt
    this.dispbtLas = this.lastMonth.dispbt
    return this.dispbtCur + this.dispbtLas
}
energia(){
    this.energia_f1 = this.f1 + this.pef1
    this.energia_f2 = this.f2 + this.pef2
    this.energia_f3 = this.f3 + this.pef3
    this.price_f1 = this.energia_f1 *  this.currentMonth.price_tutela.triorario.f1
    this.price_f2 = this.energia_f2 *  this.currentMonth.price_tutela.triorario.f2
    this.price_f3 = this.energia_f3 *  this.currentMonth.price_tutela.triorario.f3
    this.totale = this.price_f1 + this.price_f2 + this.price_f3
    return this.totale
}
Vendita(){
    this.totaleVendita = (this.pcv() + this.pd() + this.dispbt() + this.energia())
    return this.totaleVendita
}
//#endregion

//#region Trasporto
quotaVariabileTrasporto(){
    this.consume = ( this.f1 + this.f2 + this.f3 ) / 2
    this.qvtCur = this.consume * this.currentMonth.trasporto_quota_energia
    this.qvtLas = this.consume * this.lastMonth.trasporto_quota_energia
    return this.qvtCur + this.qvtLas
}
quotaFissaTrasporto(){
    this.qftCur = this.currentMonth.trasporto_quota_fissa
    this.qtfLas = this.lastMonth.trasporto_quota_fissa
    return this.qtfLas + this.qftCur
}
quotaPotenzaTrasporto(){
    this.potenza = this.utenza.potenza
    this.potCur = this.potenza * this.currentMonth.trasporto_quota_potenza
    this.potLas = this.potenza * this.lastMonth.trasporto_quota_potenza
    return this.potCur + this.potLas
}
Trasporto(){
    this.totaleTrasporto = (this.quotaFissaTrasporto() + this.quotaVariabileTrasporto() + this.quotaPotenzaTrasporto())
    return this.totaleTrasporto
}
//#endregion

//#region Oneri
quotaVariabileOneri(){
    this.consume = (this.f1 + this.f2 + this.f3) / 2
    let kw = this.consume / 30.41666
    let soglie = [0 , 4.931506, 600] 
    if(kw > soglie[1]){
       this.oneriSottoCur =  (soglie[1] * 30.41666) * this.currentMonth.oneri_quota_energia_sotto
       this.oneriSottoLas =  (soglie[1] * 30.41666) * this.lastMonth.oneri_quota_energia_sotto
       this.diferents =  ( kw - soglie[1]) * 30.41666
       this.oneriSopraCur = this.diferents * this.currentMonth.oneri_quota_energia_sopra
       this.oneriSopraLas = this.diferents * this.lastMonth.oneri_quota_energia_sopra
       this.quotaSotto = this.oneriSottoCur + this.oneriSottoLas
       this.quotaSopra = this.oneriSopraCur + this.oneriSopraLas
       return this.quotaSotto + this.quotaSopra
    }
    if(kw <= soglie[1]){
       this.oneriSottoCur =  (kw * 30.41666) * this.currentMonth.oneri_quota_energia_sotto
       this.oneriSottoLas =  (kw * 30.41666) * this.lastMonth.oneri_quota_energia_sotto
       return this.oneriSottoCur + this.oneriSottoLas
    }
    
}

quotaFissaOneri(){
    this.quotaNonResidente = this.currentMonth.oneri_quota_fissa + this.lastMonth.oneri_quota_fissa
    return this.quotaNonResidente
}
Oneri(){
    this.totaleOneri =  this.quotaVariabileOneri()
    return this.totaleOneri
}
//#endregion
   
//#region Imposte
accisa(){
    this.consume = ( this.f1 + this.f2 + this.f3 ) / 2
    let kw = this.consume / 30.41666
    let soglia = [0 , 4.931506, 600] 
    if(kw > soglia[1]){
       this.accisaSottoCur =  (soglia[1] * 30.41666) * 0
       this.accisaSottoLas =  (soglia[1] * 30.41666) * 0
       this.diferenta =  ( kw - soglia[1]) * 30.41666
       this.accisaSopraCur = this.diferenta *  0.02270
       this.accisaSopraLas = this.diferenta *  0.02270
       this.accisaSotto = this.accisaSottoCur + this.accisaSottoLas
       this.accisaSopra = this.accisaSopraCur + this.accisaSopraLas
       return this.accisaSotto + this.accisaSopra
    }
    if(kw <= soglia[1]){
       this.accisaSottoCur =  (kw * 30.41666) * 0
       this.accisaSottoLas =  (kw * 30.41666) * 0
       return this.accisaSottoCur + this.accisaSottoLas
    }
}

iva(){
   this.ivaVendita =  (this.Vendita() * this.ivaValue) / 100
   this.ivaTrasporto = ( this.Trasporto() * this.ivaValue ) / 100
   this.ivaOneri = ( this.Oneri() * this.ivaValue ) / 100
   this.ivaAccisa = ( this.accisa() * this.ivaValue ) / 100
   this.totaleIva = (this.ivaVendita + this.ivaTrasporto + this.ivaOneri + this.ivaAccisa)
   return this.totaleIva
}

totaleNR(){
    this.totaleNr = ( this.quotaFissaOneri() * this.ivaValue ) / 100
    return this.totaleNr + this.quotaFissaOneri()
}
Imposte(){
    this.totaleImposte = this.accisa() + this.iva()
    return this.totaleImposte
}
//#endregion

TotaleBolletta(){
    this.totaleBolletta = (this.Vendita() + this.Trasporto() + this.Oneri() + this.Imposte())
    return this.totaleBolletta
}
TotaleBollettaNonResidente(){
    this.totaleBollettaNonResidente = (this.Vendita() + this.Trasporto() + this.Oneri() + this.Imposte()+ this.totaleNR())
    return this.totaleBollettaNonResidente
}

objBolletta(){
        return {
            bolletta: this.TotaleBolletta(),
            bollettaNr: this.TotaleBollettaNonResidente(),
            vendita: this.Vendita(),
            trasporto: this.Trasporto(),
            oneri: this.Oneri(),
            imposte: this.Imposte(),
            quotaFissaNonResidente: this.quotaFissaOneri(),
            nonResidente: this.totaleNR()

        }
    }

}

export default BollettaTd