import React,{Component} from 'react'
import './notifica.css'
import logo from '../../Media/logo-k-120.png'
import moment from 'moment'
moment.defineLocale('it',
{relativeTime : {
    future : '%s fa',
    past : '%s fa',
    s : 'qualche secondo',
    m : 'un minuto',
    mm : '%d minuti',
    h : 'un\'ora',
    hh : '%d ore',
    d : 'un giorno',
    dd : '%d giorni',
    M : 'un mese',
    MM : '%d mesi',
    y : 'un\'anno',
    yy : '%d anni'
}})

class Notifica extends Component {
    constructor(props){
        super(props)
        this.state = {
            notifica: this.props.not
        }
    }
    handleLink =()=> {
        if(this.state.notifica.link){
        window.open(this.state.notifica.link, '_blank')
        }
    }
    render(){ 
      const date =  new Date(this.state.notifica.time)
    return( 
        <div className="notification" key={this.state.notifica.id}>
            <div className="notification-image">
               <img 
               src={this.state.notifica.image ? this.state.notifica.image : logo } 
               alt={this.state.notifica.altImage ? this.state.notifica.altImage : 'Immagine logo kilowatt energia'} />
            </div>
            <div className="notification-content">
                <div className="notification-title">{ this.state.notifica.title }</div>
                <div className="notification-caption">
                <p>{this.state.notifica.intro ? this.state.notifica.intro : 'Kilowatt'}</p>
                <p>{this.state.notifica.caption ? this.state.notifica.caption : ''}</p>
                </div>    
                <div className="notification-time"> 
                 {
                  this.state.notifica.time && 
                  moment(date).fromNow()
                 }   
                </div>
            </div>
            <div className="notification-link" onClick={this.handleLink}>
                {
                 this.state.notifica.linkImage &&
                <span>
                <img src={this.state.notifica.linkImage} alt="imagine notification" />
                 {
                this.state.notifica.linkText &&
                <small>{this.state.notifica.linkText}</small>
                 }
                </span>
                }
                
            </div>
        </div>
        ) 
    }
}
export default Notifica