import React ,{Component} from 'react'
import './condition.css'
import {IconCloseRound }from '../UI/Icons/IconClose'
import logo from '../../Media/logo-k-120.png'


class Condition extends Component{

    render(){
    const { isOpen } = this.props
        return(
            <div className="div-absolute-100">
        <header className="App-header">
        <span className="header-image"></span>
        <span className="header-title">Info App
          </span>
          <span className="header-caption" >
            <span onClick={isOpen.bind(this, false)} >
            <IconCloseRound />
            </span>
          </span>
        </header>
            <div className="div-center-column">
                <div className="Conditions-container">
                <div className="Conditions-content">
                        <span className="Conditions-intro">
                        <img src={logo} alt="imagine per condizioni d'uso"/>    
                        <h2>KILOWATT</h2>    
                        </span>
                <span>
                <p className="italic">
                    “Il mondo non è perfetto. Scegliere come vederlo è l’unico vero potere che abbiamo.” <br />
                    (Torey L. Hayden)</p>

                <p>
                KILOWATT è un servizio rivolto agli utenti che hanno l'interesse di migliorare
                e ottimizare la spesa della propria fornitura di energia elettrica,
                l'alternative sono tante ma il vero problema e l'insufficente conoscenza e 
                le difficoltà di comprendere l'aggiornamento continuo delle normative che 
                regolano il mercato elettrico.<br/>
                A questo proposito KILOWATT permette all'utente mediante l'uso dell'App
                di creare un utenza tipo e comprendere la spesa totale, 
                in base alle condizioni tecnico-economiche definite dalla
                Autorità di Regolazione di Energia Reti e Ambiente ( ARERA ).
                </p>
                <p>
                KILOWATT è puramente indicativo e serve esclusivamente ad orientare 
                l'utente nel mercato libero dell'energia elettrica.<br/>    
                KILOWATT non sostituisce le condizioni tecnico economiche definite 
                dall'ARERA.
                <br/>
                Le condizioni variano ogni mese in base alle normative vigenti e alle 
                delibere del ARERA.<br/>
                E' Da considerare che le differenze sul calcolo possono essere dovute ad 
                arrotondamenti, conguagli ed ulteriori costi presenti nelle rispettive bollette
                e che non fanno calcolo nella nostra simulazione.
                <br />
                Le condizioni tecnico economiche possono essere consultate nel sito del ARERA.
                </p>
                <p>
                Il corrispetivo energia applicato fa riferimento al prezzo d'acquisto  
                PUN (<small>Prezzo Unico Nazionale</small>  ) definito e pubblicato dal GME
                ( Gestore Mercati Energetici ), relativo al mese di consumo.
                Per conoscere i prezzi stabilite si può verificare sul sito della GME
                l'App fa uso di una percentuale di default per ogni tipo di destinazione, diviso in fasce orarie:
                Domestico: F1 = 41%, F2 = 22% , F3 = 37%.
                Business: F1 = 45%, F2 = 25%, F3 = 30%.
                </p>
                <p>
                Periodicità del ciclo di fatturazione per ogni destinazione:
                </p>
                <ul>
                <li>Domestico: calcolo bimestrale con l'opzione da scegliere se l'utente è o no è
                residente e quindi applicare o meno il relativo costo del canone tv.</li>
                <li>Business: calcolo  mensile con l'opzione bimestrale per contatori fino a 16kw di potenza,
                ( in questo caso si può inserire la potenza impegnata ). 
                </li>
                </ul>  
                <p>
                Il nostro servizio viene aggiornato constatemente in relazione alle 
                normative che regolano il mercato elettrico. 
                </p>      
                <small>ultimo aggiornamento 21 Ottobre 2019</small>
                </span>
               <div className="termini-duso">
             <h3>Termini e Condizioni d'uso</h3> 
          
             <span>la invitiamo a leggere attentamente le condizioni d'uso dell'App
             <br/>
              Accedendo e utilizzando l’App o qualsiasi sua funzionalità su qualsiasi computer,
              smartphone, tablet o altro dispositivo, l’utente conferma di aver attentamente 
              letto e accettato il presente documento recante Termini e Condizioni d’uso dell’App.
             </span> 
             <p>l'App permette di calcolare i costi relativi alle utenze di energia elettrica con riferimento
                 alle quote di essa attualmente in vigore. L'App si pone come obiettivo informare i propri utenti 
                 della relativa spesa che può sostenere per l'utenza di energia elettrica.</p> 
              <p>Titolare: l'App KILOWATT é di proprietà di Yumber Ramirez e viene messa a disposizione degli utenti via internet 
                  per smartphone e desktop</p>  
            <p>Restrizioni dell'Utente: L'App e destinata esclusivamente per uso personale ed e fornita per l'utilizzo solo nel territorio italiano,
                Per l'utilizzo dell'App si richiede l'accesso a internet non si chiedono login e dati del cliente ma vengono gestite delle
                sessioni. 
            </p>
            <p>Responsabilità dell'Utente: L’utente risponderà in via esclusiva del corretto e 
                continuo funzionamento della connettività Internet e/o telefonica dalla medesima 
                destinata all’esecuzione del servizio <br />
                L’utente non può copiare, distribuire, trasmettere,
                 modificare o manomettere in qualsiasi modo il materiale contenuto nell’App.
                </p>
            <p>Esonero di Responsabilità: Fatto salvo quanto previsto della legge, la proprietà non riconoscerà 
                alcuna garanzia esplicita o implicita , per quanto riguarda il funzionamento dell'App e di tutti i servizi
                a cui accede tramite l'App.
                L'Utente riconosce e accetta di essere l'unico responsabile per
                eventuali danni al dispositivo attraverso il quale accede all'App.
            </p>
            <p>Fruizione dei servizi: L’utente si impegna ad utilizzare i servizi nel rispetto
                delle loro caratteristiche e specifiche tecniche ed in conformità a quanto 
                previsto nelle presenti condizioni, nonché in conformità alle leggi, regolamenti e 
                usi vigenti in materia. L’utente, inoltre, si impegna a non utilizzare i 
                servizi in modo improprio e ad informare senza indugio la propietà di 
                eventuali usi impropri dei medesimi da parte di terzi dei quali dovesse 
                venire comunque a conoscenza. L’utente, infine, si impegna a favorire in
                ogni modo alla proprietà nell'esecuzione dei servizi ed a fare il 
                possibile affinché le stessa possa utilmente tutelarsi contro terzi che 
                abbiano violato i rispettivi diritti</p>
                <p>Protezione dei dati e Privacy Policy: i dati personali e/o altre informazioni relative all'Utente
                    saranno utilizzati solo in conformità con la norma sulla privacy, si prega di leggere
                    attentamente la informativa sulla privacy e policy che trova nell'App.
                    Facendo uso dell'App si acconsente all'uso di tale informazioni in accordo con la 
                    medesima. 
                </p>
                <p>Modifiche ai presenti termini: La proprietà si riserva di modificare i termini
                    e condizioni d'uso in qualsiasi momento e senza preavviso. Con effeto dal momento 
                    della pubblicazione delle condizioni modificate, qualsiasi utilizzo successivo dell'App
                    costituirà esplicita accettazione di tali termini e condizioni d'uso modificati.</p> 
            </div> 

            </div>
            
            
            </div>
            
            </div>
        </div>
        )
    }
}
export default Condition