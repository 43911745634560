import React,{ Component} from 'react'
import Cover from '../UI/Cover'
import Cookies from 'js-cookie'
import './footer.css'

class Footer extends Component{
    state = {
        cover: Cookies.get('Swa')
      }
      componentDidMount = () => { 
        const doco = document.querySelector('.pps')
        const appContent = document.querySelector('.App-content')
        const set = setInterval(()=> {
          if(document.readyState ==='complete'){
            clearInterval(set)
            if(doco){
             doco.classList.add('open') 
            }
            
          }
        },200)
        if(appContent && doco){
        appContent.addEventListener('click', ()=> {
        doco.classList.remove('open')
        let date = new Date().getTime()
        let keys = 'Kw-'+date
        Cookies.set('Swa', keys)
        this.setState({cover: true})
        })
        }
      }
      
    render(){
        return(
            <footer className="App-footer">
            <Cover cov={this.state.cover}  />
            </footer>
        )
    }
}

export default Footer