import React,{Component} from 'react'

class Back extends Component{
    constructor(props){
        super(props)
        this.clickShow = this.props.click.bind(this)
    }
    render(){
        return(
            <svg width="300" height="50"  viewBox="0 0 300 50" >
            <circle cx="100 " cy="25" r="23" fill="none" stroke="#ffd700"
            strokeWidth="4"
            />
            <circle cx="100" cy="25" r="28" fill="transparent" onClick={()=>this.clickShow(true)}/>
            
            <circle cx="90 " cy="25" r="4" fill="white"/>
            <circle cx="100 " cy="25" r="4" fill="white"/>
            <circle cx="110 " cy="25" r="4" fill="white"/>
             
             <text x="140" y="20" fontSize="14" fill="white">Prova</text>
             <text x="140" y="34" fontSize="14" fill="white">Ancora</text>
            </svg>
        )
    }
}

export default Back