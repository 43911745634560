import React, {Component} from 'react'
import Snap from 'snapsvg-cjs'

class BtnCalcola extends  Component{
    constructor(props){
        super(props)
        this.handleClickProps = this.props.clico.bind(this)
        this.handleComp = this.handleComp.bind(this)
    }
    handleComp = () => {
        const butt =  Snap("#button-calcola")
        butt.animate({ transform: 's0.9'}, 400, window.mina.bounce, function(){
            butt.animate({ transform: 's1'}, 400, window.mina.bounce)     
        })
        this.handleClickProps(true)
        
    }

    render(){
        return(
            <div className="calcola">
              <svg width="300" height="50" viewBox="0 0 300 50" >
                  <g id="button-calcola" onClick={this.handleComp}>
                    <rect x="100" y="5" width="100" height="40" fill="#7aa1f5"  />
                    <text x="120" y="32" fontSize="20" fontWeight="bold" fill="white">Avanti</text>
                    <rect x="70" y="0" width="160" height="50" fill="transparent"/>
                  </g>
                </svg>
            </div>
        )
    }
}

export default BtnCalcola
